.inner-page {
  min-height: calc(100vh - 60px);
  @extend %background-pattern;

  &__container {
    width: 1000px;
    margin: 0 auto;
    padding: 70px 0 50px;

    @include tablet {
      width: 708px;
    }
    @include mobile {
      width: 100%;
      padding: 50px 0;
    }
  }

  &__title {
    font-size: 45px;
    letter-spacing: 3.2px;
    text-align: center;
    text-transform: uppercase;
    font-family: $extrabold;
    margin-top: 0;
    margin-bottom: 56px;

    @include mobile {
      margin-bottom: 36px;
      line-height: 1.3em;
    }
  }

  &__back-button {
    margin-bottom: 20px;
  }

  &__body {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
  }

  &__sidebar {
    width: 300px;
    margin-right: 20px;

    @include tablet {
      width: 344px;
    }
    @include mobile {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    width: 605px;

    @include tablet {
      width: 344px;
    }
    @include mobile {
      width: 100%;
    }
  }
}
