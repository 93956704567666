.dogs-card {
  @extend %hover-card;
  position: relative;
  border-radius: 3px;
  background: #fff;
  margin-bottom: 20px;
  @extend %flex-container;

  @include not-desktop {
    width: 290px;
    flex-direction: column;
    margin: 0 15px 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    width: 200px;
    @extend %flex-container-columns-centred;
    padding: 30px 25px;
    box-sizing: border-box;
    border-right: 5px solid $accent-primary-color;

    @include not-desktop {
      width: 100%;
      border-right: none;
      border-bottom: 5px solid $accent-primary-color;
    }
  }

  .avatar {
    position: relative;
    z-index: 2;

    &__container {
      box-shadow: none;
      width: 140px;
      height: 140px;
      background-color: $common-medium-gray-color_accent;
      margin: 0;
      border: none;
    }

    &__img {
      width: 140px;
      height: 140px;
    }
  }

  &__content {
    padding: 30px;
    width: calc(100% - 200px);

    @include not-desktop {
      width: 100%;
      padding: 30px 20px 40px;
    }
  }

  &__view {
    padding-right: 30px;
    white-space: nowrap;
    @extend %flex-container-columns-centred;

    @include not-desktop {
      padding: 0 0 40px;
    }

    .view-link {
      position: relative;
      z-index: 2;
    }
  }

  &__link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:hover {
    .view-link {
      &__arrow {
        transform: translateX(5px);
      }
    }
  }

  &__headline {
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__title {
    font-size: 20px;
    font-family: $bold;
    color: $accent-primary-color;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 18px;
    color: $common-gray-color;
  }

  &__list {
    width: auto;
  }

  &__item {
    margin-bottom: 16px;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &__title {
      font-family: $bold;
      margin-bottom: 2px;
    }

    &__content {
      &_link {
        position: relative;
        z-index: 2;
        font-family: $bold;
        color: $accent-secondary-color;
        transition: color $duration;

        &:hover {
          color: $accent-secondary-color-hover;
          @extend %faster;
        }
      }

      &_ready {
        color: $accent-health-color;
      }

      &_warning {
        color: #e3ba00;
        position: relative;
        z-index: 2;
      }

      &_error {
        color: $warning-color;
        position: relative;
        z-index: 2;
      }
    }
  }
}
