.customer-dogs {
  &__add {
    @extend %flex-container-rows-centred-end;
    margin-bottom: 20px;
  }

  &__list {
    @include not-desktop {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      justify-content: center;
    }
  }
}
