.customer-packages {
  position: relative;
  background: none;
  &__list {
    margin: 0 auto;
    max-width: 860px;
    @include mobile {
      max-width: 295px;
    }
  }
}
