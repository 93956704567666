.info-block {
  @extend %flex-container-columns-centred;
  padding: 15px;
  color: $common-dark-gray-color;
  overflow: hidden;

  @include tablet {
    padding: 40px 30px;
    flex-direction: row;
    justify-content: space-between;
  }
  @include mobile {
    padding: 30px;
  }

  &:hover {
    .info-block {
      &__content {
        transform: translateY(0);
      }

      &__button {
        transform: translateY(0);
        transition: transform $medium-transition-time/2, opacity $medium-transition-time/2, background-color $medium-transition-time, color $medium-transition-time, box-shadow $medium-transition-time;
        transition-delay: $medium-transition-time/4;
        opacity: 1;
      }

      &__button--disabled {
        transform: translateY(0);
        transition: transform $medium-transition-time/2, opacity $medium-transition-time/2, background-color $medium-transition-time, color $medium-transition-time, box-shadow $medium-transition-time;
        transition-delay: $medium-transition-time/4;
        opacity: 0.75;
      }
    }
  }

  &__content {
    max-width: 255px;
    transform: translateY(32px);
    transition: transform $duration;

    @include tablet {
      transform: none;
      width: 60%;
      max-width: 100%;
    }
    @include mobile {
      transform: none;
      max-width: 100%;
    }
  }

  &__title {
    font-size: 16px;
    font-family: 'ProximaNova-Bold';
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;

    @include tablet {
      text-align: left;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 1.5;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__button {
    @extend .button;
    margin-top: 26px;
    opacity: 0;
    transform: translateY(20px);
    transition: transform $medium-transition-time/2, opacity $medium-transition-time/2, background-color $medium-transition-time, color $medium-transition-time, box-shadow $medium-transition-time;
    width: 160px;

    @include tablet {
      margin: 0;
      opacity: 1;
      transform: none;
      width: 160px;
    }
    @include mobile {
      margin: 0;
      opacity: 1;
      transform: none;
      width: 160px;
    }
  }

  &__button--disabled {
    @extend .button;
    margin-top: 26px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: transform $medium-transition-time/2, opacity $medium-transition-time/2, background-color $medium-transition-time, color $medium-transition-time, box-shadow $medium-transition-time;
    width: 160px;

    @include tablet {
      margin: 0;
      opacity: 0.75;
      transform: none;
      width: 160px;
    }
    @include mobile {
      margin: 0;
      opacity: 0.75;
      transform: none;
      width: 160px;
    }
  }
}

