.directory {
  @extend %background-pattern;
  @extend %dashboard__list;

  @include mobile {
    padding-right: 10px;
    padding-left: 10px;
  }

  @include tablet {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.directory__top {
  @extend %flex-container-rows-centred;
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;

  @include mobile {
    flex-direction: column;

    .select-control,
    .Select-control {
      width: 220px;
    }
  }

  @include tablet {
    justify-content: flex-start;

    .select-control,
    .Select-control {
      width: 190px;
    }
  }
}

.directory__add-new {
  @extend %flex-container-rows-centred;
  position: absolute;
  right: 0;

  @include mobile {
    position: relative;
    margin-top: 20px;
  }
}

.directory__header {
  @extend %table__header;
}

.directory__content {
  @extend %dashboard__content;
  position: relative;
  margin: 0;
}

.directory__grid {
  @extend %flex-container-rows-wrapped-between;
  padding: 30px 20px;

  @include not-desktop {
    padding: 21px;
  }

  @include mobile {
    justify-content: center;
  }
}

.directory__view-icon {
  fill: $common-gray-color;
  margin-left: 20px;
  width: 42px;

  @include mobile {
    display: none;
  }
}

.directory__filter-tags {
  @extend %flex-container-columns-centred;
  padding: 17px 0;
  margin-bottom: 30px;
  border-top: 1px solid $common-medium-gray-color_accent;
  border-bottom: 1px solid $common-medium-gray-color_accent;
  text-align: center;
  min-height: 123px;
}
