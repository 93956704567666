.training-programs {
  position: relative;
  &__title {
    font-size: 45px;
    font-weight: normal;
    font-family: 'ProximaNova-Extrabld';
    text-align: center;
    letter-spacing: 3.2px;
    margin: 0 auto 60px;
    text-transform: uppercase;
    color: $common-dark-gray-color;
  }

  &__list-wrapper {
    @extend %flex-container-rows-wrapped-centred;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &__item {
    width: 300px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .08);
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    transition: box-shadow $duration;
    margin: 0 10px 20px;

    &:hover {
      transition-duration: $duration/2;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, .18);
    }
  }

  &__item-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(100% - 245px);
    padding: 80px 0;
  }

  &__item-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    color: $common-dark-gray-color;
    margin: 0 auto 10px;
    padding: 0 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 295px;
  }

  &__item-text {
    margin: 0 20px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: center;
    color: $common-dark-gray-color;
  }

  &__image {
    border-bottom: 5px solid $accent-primary-color;
    height: 200px;
    overflow: hidden;
    background-color: #ccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__buttons {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateZ(0) translateX(-50%);
    transition: transform $medium-transition-time/2, opacity $medium-transition-time/2, background-color $medium-transition-time, color $medium-transition-time, box-shadow $medium-transition-time;
    opacity: 0;
    transition-delay: 0;
    @include not-desktop {
      transform: translateY(-20px) translateX(-50%) translateZ(0);
      opacity: 1;
    }
  }

  &__get-started {
    width: 100%;
    margin: 0 auto 20px;
  }

  &__more-button {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: .8px;
    text-align: center;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    color: $common-dark-gray-color;
    transition: color $duration;
    &__icon {
      margin: -2px 0 0 5px;
      transition: transform $duration, fill $duration;
    }
    &:hover &__icon {
      transition-duration: $duration/2;
      transform: translateX(5px);
      fill: $accent-secondary-color;
    }
    &:hover {
      color: $accent-secondary-color;
      transition-duration: $duration/2;
    }
  }

  &__item-content {
    transition: transform $short-transition-time;
    transition-delay: $small-transition-time/2;
    @include not-desktop {
      transform: translateY(-55px);
    }
  }
  &__item:hover &__item-content {
    transition-delay: 0;
    transform: translateY(-55px);
  }
  &__item:hover &__buttons {
    transition-duration: transform $short-transition-time/2;
    transition-delay: $small-transition-time;
    transform: translateY(-20px) translateX(-50%);
    opacity: 1;
  }
}
