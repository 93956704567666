.classes-slider {
  background: #f8f8f8;
  padding: 50px 120px;
  position: relative;

  @include not-desktop {
    padding: 50px 40px;
  }

  &__swiper {
    overflow: hidden;
  }

  &__title {
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    font-family: $extrabold;
    margin-bottom: 25px;
  }
  &__item {
    @extend .training-programs__item;
    min-height: auto !important;
    width: 335px;
  }
  &__image {
    @extend .training-programs__image;
    height: 180px;
  }
  .info-block__button {
    min-width: 200px;
  }
  .info-block__title {
    margin-bottom: 3px;
    @include not-desktop {
      margin-bottom: 16px;
    }
  }
  .info-block__location {
    text-align: center;
    font-size: 15px;
    font-family: ProximaNova-Bold,Helvetica,sans-serif;
    color: #98999d;
    margin-bottom: 10px;
  }

  .info-block__days {
    text-align: center;
    font-size: 15px;
    font-family: ProximaNova-Bold,Helvetica,sans-serif;
    color: #98999d;
  }

  .info-block__description{
    text-align: center;
    font-size: 15px;
    font-family: ProximaNova-Bold,Helvetica,sans-serif;
    padding-top:20px;
    padding-bottom:20px;
    border-bottom: 1px solid $common-medium-gray-color_accent;
  }
  .info-block__time{
    @include not-desktop {
      margin-bottom: 16px;
      padding-top:0;
    }
  }
  &__info-block {
    min-height: 150px;
    padding-top: 25px;

    @include not-desktop {
      flex-direction: column;
    }
  }
  .swiper-button-next:before, .swiper-button-prev:before {
    border-color: #8e8e8e;
  }
  .swiper-button-disabled {
    opacity: .3;
  }
}
