.classes-history {
  &__item {
    margin-bottom: 5px;

    .schedule-card__body {
      padding-right: 160px;

      @include tablet {
        padding-right: 100px;
      }

      @include mobile {
        padding-right: 0;
      }
    }
    .schedule-card__time-item {
      display: block;

      &:after {
        display: none;
      }
    }
  }
}
