.daily-notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 13px 15px 90px;
  min-height: 76px;
  position: relative;
  cursor: pointer;
  background: #fff;
  transition: background $medium-transition-time;
  color: $common-dark-gray-color;

  &_unread {
    background-color: #f3f3f3;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }

  &:hover:not(&_unread) {
    background: #e8e8e8;
    @extend %faster;
  }

  &__image {
    position: absolute;
    width: 65px;
    height: 48px;
    left: 13px;
    top: 15px;
    background-position: center;
    background-size: cover;
    background-color: $common-medium-gray-color_accent;
    @extend %flex-container-columns-centred;

    .avatar__default-placeholder {
      background: none;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .7px;
    margin-bottom: 10px;
  }

  &__date {
    color: $common-gray-color;
  }
}
