.packages-card {
  width: 100%;
  min-height: 228px;
  display: flex;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .08);
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  justify-content: flex-start;
  transition: box-shadow $duration;
  margin: 0 0 20px;
  cursor: pointer;
  color: $common-gray-color;
  @include mobile {
    flex-direction: column;
  }
  &:hover {
    @extend %faster;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, .18);
  }

  &__container {
    padding: 25px 30px;
    display: flex;
    width: calc(100% - 200px);
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    @include mobile {
      width: 100%;
      padding: 25px 20px;
      justify-content: flex-start;
    }
  }

  &__head {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
  }

  &__head-text {
    margin: 0;
    @include mobile {
      margin-bottom: 5px;
    }
  }

  &__image {
    max-width: 30%;
    width: 200px;
    background-color: #ccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-right: 5px solid $accent-primary-color;
    @include mobile {
      width: 100%;
      max-width: 100%;
      height: 195px;
      border-right: none;
      border-bottom: 5px solid $accent-primary-color;
    }
  }

  &__title {
    font-size: 20px;
    font-family: $bold;
    color: $accent-primary-color;
    text-transform: uppercase;
    display: block;
    margin: 0;
    letter-spacing: 1px;
  }

  &__subline {
    font-size: 16px;
    font-family: $bold;
    color: $common-gray-color;
    display: block;
    margin-top: 3px;
  }

  &__price {
    font-size: 30px;
    font-family: 'Proxima Nova Regular';
    font-weight: bold;
    letter-spacing: 1.5px;
    color: $common-dark-gray-color;
  }

  &__text {
    color: $common-dark-gray-color;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: .8px;
    margin: 0 0 12px;
    display: block;
    max-height: 35px;
    overflow: hidden;
    @include mobile {
      max-height: 100%;
    }
  }

  &__notice {
    max-width: 405px;
    display: block;
    margin: 0 0 17px;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: .7px;
    span {
      &:first-child {
        @include mobile {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

  &__button {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.67;
    letter-spacing: .8px;
    text-align: center;
    display: inline-flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    color: $common-dark-gray-color;
    transition: color $duration;
    @include mobile {
      width: 100%;
    }
    &__icon {
      margin: -2px 0 0 5px;
      transition: transform $duration, fill $duration;
    }
    &:hover &__icon {
      fill: $accent-secondary-color;
    }
    &:hover {
      color: $accent-secondary-color;
      transition-duration: $duration/2;
    }
  }
  &:hover &__button__icon {
    transition-duration: $duration/2;
    transform: translateX(5px);
  }
}
