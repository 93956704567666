.form__divider {
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $common-medium-gray-color;
}

.form__divider_spacer {
  width: 100%;
  padding-bottom: 1rem;
}

.form__aligner {
  @extend %flex-container-rows-wrapper-start-between;
  margin-top: .86rem;

  &:first-of-type {
    margin-top: 0;
  }

  .input-control,
  .select-control {
    margin-top: 0;
  }

  @include mobile {
    margin-top: 0;
    flex-flow: column nowrap;

    .input-control,
    .select-control {
      margin-top: 1rem;
    }
  }

  @include tablet {

    .input-control,
    .select-control {
      margin-top: 1rem;
    }
  }
}

.form__aligner_margin-top {
  >div {
    margin-top: 1rem !important;

    &:first-of-type {
      margin-top: 0 !important;
    }
  }
}

.form__field-description {
  @extend .checkbox-list-control__title;
  color: $common-dark-gray-color;
  width: 100% !important;
  margin-bottom: 10px;
}

// todo: how should rules inside modifiers like this be build?
.form__aligner_60-40 {
  >div {
    width: 38.73%;

    &:first-of-type {
      width: 59.33%;
    }
  }

  @include mobile {
    >div {
      width: 100%;

      &:first-of-type {
        width: 100%;
      }
    }
  }

  @include tablet {
    >div {
      width: 100%;

      &:first-of-type {
        width: 100%;
      }
    }
  }
}

.form__aligner_50-50 {
  >div {
    width: 49%;
  }

  @include mobile {
    >div {
      width: 100%;
    }
  }
}

.form__aligner_33-33-33 {
  >div {
    width: 32%;
  }

  @include mobile {
    >div {
      width: 100%;
    }
  }
}

.form__aligner_39-28-29 {
  >div {
    width: 28%;

    &:first-of-type {
      width: 39%;
    }

    &:last-of-type {
      width: 29%;
    }
  }

  @include mobile {
    >div {
      width: 100%;

      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.form__aligner_36-36-24 {
  >div {
    width: 36.3%;

    &:first-of-type {
      width: 36.3%;
    }

    &:last-of-type {
      width: 24%;
    }
  }

  @include mobile {
    >div {
      width: 100%;

      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.form__aligner-left {
  @extend %flex-container-columns;
}

.form__select-aligner {
  @extend %flex-container-columns;
  margin-top: 2.1rem;

  &:first-of-type {
    margin-top: 0;
  }

  @include mobile {
    margin-top: 3rem;
  }
}

.form__error {
  @extend %typo-from-error;
  margin-top: 1.88rem;
  color: $warning-color;
  text-align: center;

  @include tablet {
    margin-top: 2.39rem;
  }

  @include mobile {
    margin-top: $common-margin-top-big;
  }
}

.form__aligner_100-0 {
  >div {
    width: 100%;
  }

  @include mobile {
    >div {
      width: 100%;
    }
  }
}

.form__column {

  // @extend %flex-container-rows-start-between;
  &>div {
    margin-top: .86rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @include mobile {
    &:first-child {
      order: 1;
    }

    &:last-child {
      order: 0;
    }
  }

  &.form__column_block {
    margin-top: .86rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.form__separator {
  width: 100%;
  height: 1px;
  background-color: $common-medium-gray-color_accent;
  margin: 20px 0;
}

.form__flex-container {
  display: flex;
  justify-content: center;
}

.form__middle-text {
  @extend %typo-content-card-header;
  color: $common-dark-gray-color;
  margin-top: 30px;
  text-align: center;
}

.form__left-text {
  text-align: left;
}

.form__bold-text {
  font-family: 'ProximaNova-Bold';
  margin-bottom: 5px;
}

.form__margin-top-15 {
  margin-top: 15px;
}

.form__help {
  text-align: right;

  @include mobile {
    margin-bottom: 0;
  }
}

.form__help-link {
  color: $accent-secondary-color;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  transition: color $duration;

  &:hover {
    color: $accent-secondary-color-hover;
    @extend %faster;
  }
}

.form__section-title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: $bold;
}

.form__section-subtitle {
  font-size: 16px;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}