.dashboard-page {
  @extend %background-pattern;
  min-height: 100%;
  padding: 0 40px;

  @include tablet {
    padding: 0 27px 27px;
  }

  @include mobile {
    padding: 0 12px 12px;
    min-height: 350px;
  }
}

.dashboard-page__navbar > div {
  @extend %flex-container-rows-centred;
}
