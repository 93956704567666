.customer-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 800px;
  min-height: 60px;
  @include not-desktop {
    max-width: 670px;
  }
  @include mobile {
    padding: 0 3px;
  }
}

.customer-navbar__item {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  max-height: 46px;
  display: flex;
  flex-direction: column;
  min-width: 120px;
  text-align: center;
  padding: 7px 10px;
  box-sizing: content-box;
  transition: background-color $short-transition-time;
  &:hover {
    transition-duration: $short-transition-time/2;
    background-color: $accent-secondary-color;
  }
  @include mobile {
    font-size: 9px;
    min-width: 0;
    min-height: 60px;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 7px;
  }
  svg {
    fill: #fff;
    display: block;
    margin: 0 auto;
  }
}

.customer-navbar__item_active {
  background-color: $accent-secondary-color;
}
