.featured-slider {
  overflow: hidden;
  position: relative;
  height: 100%;

  &__item {
    background-size: cover;
    background-position: center;
    background-color: #ccc;
    height: 100% !important;
    width: 100% !important;
    @extend %flex-container-columns-centred;

    @include mobile {
      min-height: 190px;
    }

    &_empty {
      background-image: url('/assets/imgs/featured-class-desktop.jpg');
    }
  }

  &__inner {
    padding: 40px;
    text-align: center;

    @include mobile {
      width: 100%;
    }
  }

  &__content {
    border-radius: 3px;
    background-color: rgba(#373e48, .85);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .08);
    display: inline-block;
    padding: 50px 60px;
    font-family: 'ProximaNova-Bold';
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 500px;
    overflow: hidden;

    @include mobile {
      padding: 30px 15px;
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: 1.1px;
    color: $accent-primary-color;

    @include mobile {
      font-size: 14px;
    }
  }

  &__name {
    font-size: 24px;
    letter-spacing: 1.4px;
    color: #fff;

    @include mobile {
      font-size: 18px;
    }
  }

  .swiper-wrapper {
    @include not-mobile {
      height: 100% !important;
    }
  }

  .swiper-pagination-bullet {
    width: 40px;
    height: 5px;
    background: #fff;
    opacity: 1;
    border-radius: 0;
    transition: background $duration;

    &:hover:not(.swiper-pagination-bullet-active) {
      background: #0ac8f1;
      @extend %faster;
    }

    @include mobile {
      width: 20px;
    }
  }

  .swiper-pagination-bullet-active {
    background: $accent-secondary-color;
  }

  .swiper-pagination {
    bottom: 20px !important;

    @include mobile {
      bottom: 10px !important;
    }
  }
}
