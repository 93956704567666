.change-password {
  &__content {
    padding-top: 15px;
    max-width: 475px;
    margin: 0 auto;
  }

  &__email {
    text-align: center;
    margin-bottom: 15px;
    font-size: 16px;
  }

  &__email-title {
    font-family: $bold;
  }

  &__submit-button {
    width: auto;
    padding-left: 34px;
    padding-right: 34px;
  }

  &__create-button {
    width: 250px;
  }

  &__sent {
    text-align: center;
    font-size: 16px;
    max-width: 350px;
    margin: 0 auto;
  }

  &__sent-title {
    font-family: $bold;
    margin-bottom: 10px;
  }
}
