.no-data {
  background: #fff;
  box-shadow: $common-shadow;
  padding: 35px 20px;
  font-size: 16px;
  line-height: 1.2;
  width: 100%;
  @extend %flex-container-columns-centred;

  &_no-borders {
    box-shadow: none;
  }

  &__icon {
    width: 200px;
    height: 200px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__headline {
    text-align: center;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $bold;
  }

  &__button {
    margin-top: 0;

    @include tablet {
      margin-top: 0;
    }
  }

  a {
    @extend .link;
    font-family: $bold;
  }
}
