.change-actions {
  width: 90px;

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    font-family: $bold;
    margin-bottom: 8px;
    cursor: pointer;
    transition: color $duration, fill $duration;

    &:hover {
      color: $accent-secondary-color-hover;
      fill: $accent-secondary-color-hover;
      @extend %faster;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon-edit {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }
  &__icon-delete {
    width: 28px;
    height: 28px;
    margin-right: 5px;
  }
}
