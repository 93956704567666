.simple-table {
  font-size: 16px;

  .ReactTable {
    border: none;
  }

  .ReactTable .rt-tbody .rt-tr-group,
  .ReactTable .rt-tbody {
    border: none !important;
  }

  .ReactTable .rt-tr:hover {
    background: #fff;
  }

  &__th {
    background: #fff;
    font-family: $bold;
  }

  &__th,
  &__td {
    border: none !important;
    &:first-child {
      text-align: left;
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }

  &__td {
    @include mobile {
      max-width: 100% !important;
    }
  }

  &__cell-title {
    display: none;

    @include mobile {
      display: block;
      font-family: $bold;
      padding-right: 20px;
    }
  }

  &__cell-content {
    @include mobile {
      text-align: right;
    }
  }

  &__cell {
    @include mobile {
      display: flex;
      justify-content: space-between;
    }
  }

  @include mobile {
    .rt-thead {
      display: none;
    }
    .rt-tbody {
      min-width: 100% !important;
      width: 100% !important;
    }
    .rt-tr {
      display: block;
    }
    &__td {
      width: 100% !important;
      padding: 0 !important;
      margin-bottom: 4px;
    }
    .rt-tr-group {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
