.customer {
  color: $common-dark-gray-color;

  @include mobile {
    overflow: hidden;
  }

  &__container {
    min-height: calc(100vh - 60px);
    @extend %background-pattern;

    @include not-desktop {
      min-height: calc(100vh - 120px);
    }
    @include tablet {
      height: auto;
      min-height: calc(100vh - 120px);
    }
  }
}
