.customer-classes {
  &__grid {
    @extend %flex-container-rows-wrapped-centred;
    margin: 0 -10px;
  }

  &__filter {
    margin-bottom: 30px;

    @include mobile {
      text-align: center;
    }
  }

  &__filter-loading {
    text-align: center;
    height: 50px;
    margin-bottom: 30px;
    @extend %flex-container-columns-centred;
    font-size: 16px;
  }

  &__filter-form {
    @extend %flex-container-rows-centred;
    margin-bottom: 30px;

    @include mobile {
      display: inline-block;
      text-align: left;
      position: relative;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__help {
    margin-left: 15px;

    @include mobile {
      position: absolute;
      margin-left: 10px;
      left: 100%;
      bottom: 12px;

      &:hover .tooltip__content {
        transform: translateY(-50%);
      }

      .tooltip__content {
        right: 100%;
        bottom: auto;
        transform: translateY(-50%);
        margin: 0 25px 0 0;
        left: auto;
        top: 50%;
        max-width: 240px;

        &:before {
          left: 100%;
          top: 50%;
          transform: rotate(-90deg);
          margin-left: 0;
          margin-top: -9px;
        }
      }
    }
  }

  &__help-icon {
    width: 25px;
    height: 25px;
    display: block;
    transition: fill $duration;
    fill: $common-dim-gray-color;

    &:hover {
      fill: $common-dark-gray-color;
      @extend %faster;
    }
  }

  .filter-tags__container {
    @extend %flex-container-columns-centred;
  }
}
