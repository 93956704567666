.customer-alert {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
  }

  &__inner {
    box-sizing: border-box;
    padding: 10px 10px;
    border-radius: 2px;
    background-color: $warning-requires-color;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    position: relative;
    margin-top: 15px;
    justify-content: space-between;
  }

  &__close {
    position: absolute;
    right: 10px;
    width: 14px;
    height: 14px;
    top: 10px;
  }

  &__close-icon {
    width: 100%;
    height: 100%;
    fill: #fff;
    cursor: pointer;
  }

  &__text {
    text-align: left;
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    padding-right: 20px;

    &:hover + .customer-alert__arrow {
      text-decoration: underline;
    }
  }

  &__arrow {
    margin-left: auto;
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    text-align: end;

    &-icon {
      width: 14px;
      height: 14px;
      fill: #fff;
      cursor: pointer;
      vertical-align: middle;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);

    &.customer-alert__enter-active {
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition: all 0.4s;
    }
  }

  &__leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);

    &.customer-alert__leave-active {
      visibility: hidden;
      transform: translate3d(100%, 0, 0);
      transition: all 0.4s;
    }
  }
}
