.reports-filter {
  position: relative;
  margin-bottom: 48px;

  &__header {
    @extend %table__header;
    width: 100%;
  }

  &__content {
    @extend %dashboard__content;
    margin: 0;
    padding: 40px 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    @include mobile {
      padding: 20px 0 0;
      //overflow: hidden;
    }
  }

  &__row {
    margin-bottom: 40px;
    padding: 0 40px;
    overflow: hidden;
    @include mobile {
      margin-bottom: 30px;
      padding: 0 20px;
    }
    @include small-mobile {
      width: 100%;
    }
    &_form {
      overflow: visible;

      @include mobile {
        padding-top: 40px;
      }
    }
    &_reports {
      display: flex;
      justify-content: center;

      @include not-desktop {
        width: 100%;
        display: initial;
      }

      @include mobile {
        border-bottom: 1px solid $common-gray-color_transparent;
        margin-bottom: 0;
        padding-bottom: 30px;
      }
    }
    .checkbox-list-control_failed {
      background: transparent !important;
      border: none;
    }
    .checkbox-list-control__error {
      text-align: center;
    }
    .checkbox-list-control__checkboxes {
      margin-top: 0;
      display: flex;
    }
    .checkbox-list-control__checkbox-name {
      padding-left: 20px;
      padding-right: 20px;
      font-family: $bold;
      font-size: 19px;
      letter-spacing: .5px;
    }
    .checkbox-list-control__checkbox-label {
      margin-left: 12px;
      margin-right: 12px;
      width: auto;
      .checkbox-list-control__checkbox-name,
      &:last-child:nth-child(odd) {
        width: auto;
      }
      @include mobile {
        margin-right: 13px;
      }
    }
    .checkbox-list-control__description {
      text-transform: uppercase;
      margin-bottom: 20px;
      font-size: 17px;
      font-family: $bold;
      text-align: center;
    }

    .date-control {
      width: 165px;
      margin-right: 10px;
      @include mobile {
        margin: 0 auto 10px;
      }
    }
    .Select-control {
      overflow: visible;
      .Select-input input {
        padding-top: 0;
      }
    }
    .Select-menu-outer {
      overflow: hidden;
    }
  }

  &__head {
    text-transform: uppercase;
    margin: 0 auto 20px;
    font-size: 17px;
    font-family: $bold;
    text-align: center;
  }

  &__bottom {
    border-top: 1px solid $common-gray-color_transparent;
    width: calc(100% + 80px);
    padding: 20px;
  }
}
