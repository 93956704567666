.filter-form {
  @extend %flex-container-rows-centred;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  .input-control,
  .select-control {
    margin: 0 5px;

    @include mobile {
      margin: 0 0 10px;
    }
  }

  .select-control:first-child,
  .input-control:first-child {
    margin-left: 0;
  }

  .select-control:last-child,
  .input-control:last-child {
    margin-right: 0;
  }

  .Select-control,
  .input-control {
    width: 220px;
  }
}

.filter-form__defined {
  @extend %flex-container;

  .select-control:last-child,
  .input-control:last-child {
    margin-left: 0;
  }

  @include mobile {
    flex-flow: column nowrap;
  }
}
