.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animate.infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes zoomIn {
  from {
    opacity: 1;
    color: #ddd;
  }

  to {
    opacity: 0;
    color: #00a1c3;
    transform: scale3d(3, 3, 3);
  }
}

.zoomIn {
  animation-name: zoomIn;
}
