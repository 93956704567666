.navbar {
  @extend %flex-container-rows-centred;
  height: $navbar-height;
  min-height: 50px;
  border-top: solid .5px $common-medium-gray-color_accent;
  border-bottom: solid .5px $common-medium-gray-color_accent;
}

.navbar__list {
  @extend %flex-container-rows-centred-between;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  @include not-desktop {
    overflow-x: auto;
  }
}

.navbar__item {
  position: relative;
  @extend %flex-container-columns-centred;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  @extend %typo-navbar-link;
  color: $common-dark-gray-color;
  white-space: nowrap;
  &:hover:after {
    transform: scaleX(1);
  }
  &:after {
    content: '';
    position: absolute;
    left: -20px;
    bottom: 0;
    display: block;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 4px solid $accent-primary-color;
    transform: scaleX(0);
    transition: transform $short-transition-time ease-in-out;
  }
}

.navbar__item:first-of-type {
  margin-left: 65px;
  @include tablet {
    margin-left: 32px;
  }
  @include mobile {
    margin-left: 7px;
  }
}

.navbar__item:last-of-type {
  margin-right: 65px;
  @include tablet {
    margin-right: 32px;
  }
  @include mobile {
    margin-right: 7px;
  }
}

.navbar__item_active {
  box-shadow: inset 0 -4px 0 $accent-primary-color;
}
