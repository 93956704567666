.profile-nav {
  display: block;
  height: 54px;

  @include desktop {
    &_pinned &__inner {
      position: fixed;
      top: 0;
      box-shadow: $common-shadow;
      z-index: 10;
    }
  }

  &__inner {
    background: #fff;
    width: 925px;
    transition: box-shadow;

    @include tablet {
      width: 708px;
    }
    @include mobile {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;

    @include not-desktop {
      display: none;
    }
  }

  &__item {
    display: block;
  }

  &__link {
    padding: 20px 18px;
    font-size: 14px;
    font-family: $bold;
    letter-spacing: .8px;
    color: $common-dark-gray-color;
    display: block;
    position: relative;
    white-space: nowrap;
    transition: color $duration;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background: $accent-secondary-color;
      transform: translateY(100%);
      transition: transform $duration;
    }

    &_active,
    &:hover {
      color: $accent-secondary-color;

      &:before {
        transform: translateY(0%);
      }
    }
  }

  &__select {
    display: none;

    @include not-desktop {
      display: block;
    }
  }
}
