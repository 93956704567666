$text-color: #6a5967;

.template-body {
  @extend %typo-template-body;
  cursor: text;
  flex-flow: column;
  color: $text-color;
  width: 100%;
  & label,
  & .template-text-area,
  & .preview {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    padding: 5px;
  }
  & .template-text-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 32px;
    -webkit-text-fill-color: transparent;
    color: inherit;
    border: 1px dashed $common-white-color;
    border-radius: $common-border-radius;
    transition: border-color $medium-transition-time;
  }
  & .template-text-area:hover {
    border: 1px dashed $common-medium-gray-color;
  }
  & .template-text-area:focus {
    border: 1px dashed $accent-primary-color;
  }
  & .template-text-area.text-area-underlay {
    z-index: 0;
    -webkit-text-fill-color: $text-color;
    color: $text-color;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

.preview .template-text-area {
  -webkit-text-fill-color: $text-color;
  &:focus {
    border: 1px dashed $common-white-color;
  }
}

.template-text-area::selection,
.template-text-area p::selection {
  background-color: $accent-primary-color;
  -webkit-text-fill-color: white;
}

.template-var {
  display: inline-block;
  -webkit-text-fill-color: $accent-primary-color;
  color: $accent-primary-color;
  background-color: rgba(255, 92, 35, .2);
  border-radius: $common-border-radius;
}
