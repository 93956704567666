.class-schedule__content {
  @extend .directory__content;
}

.class-schedule__grid {
  @extend .directory__grid;
}

.classes-schedule-table {
  @extend .directory-table;

  .rt-tbody {
    min-height: 100px;
  }

  .rt-thead {
    overflow: hidden;
  }

  .rt-tbody,
  .rt-thead {
    min-width: 1120px !important;
  }

  &.classes-schedule-table_with-description {
    .rt-tbody {
      .rt-tr {
        flex-flow: row wrap;
        .classes-schedule-table__td_description {
          width: 100% !important;
          justify-content: left;
        }
      }
    }
  }

  @include not-desktop {
    .rt-tbody {
      max-height: unset;
    }
  }
}

.classes-schedule-table__th {
  @extend %table__th;
}

.classes-schedule-table__th_select {
  @extend %table__th_select;
}

.classes-schedule-table__th_last-of-type {
  @extend .directory-table__th_last-of-type;
}

.classes-schedule-table__th_hidden {
  display: none;
}

.classes-schedule-table__td {
  @extend %table__td;
  position: relative;
  border-right: none !important;
  &:not(.classes-schedule-table__td_dropdown-menu):not(.classes-schedule-table__td_description) {
    border-bottom: none;
  }
  &.classes-schedule-table__td_dropdown-menu {
    overflow: visible;
  }
}

.dropdown_table-classes {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
}

.classes-schedule-table__class-link {
  @extend .customers-table__customer-link;
}

.classes-schedule-table__empty-content {
  @extend %table__empty-content;
}

.classes {
  @extend %background-pattern;
  @extend %dashboard__list;
  height: auto;

  @include mobile {
    padding-right: 10px;
    padding-left: 10px;
  }

  @include tablet {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.classes__top {
  @extend %flex-container-rows-centred;
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;

  @include mobile {
    flex-direction: column;

    .select-control,
    .Select-control {
      width: 220px;
    }
  }

  @include tablet {
    justify-content: flex-start;

    .select-control,
    .Select-control {
      width: 150px;
    }
  }
}

.classes__top-content {
  @extend .packages__top-content;
}

.classes__add-new {
  @extend %flex-container-rows-centred;
  position: absolute;
  right: 0;

  @include mobile {
    position: relative;
    margin-top: 20px;
  }
}

.classes__header {
  @extend .directory-table__header;
}

.classes__view-icon {
  fill: $common-gray-color;
  margin-left: 20px;
  width: 42px;

  @include mobile {
    display: none;
  }
}

.classes__filter-tags {
  @extend .directory__filter-tags;
}

.classes-schedule-table__description-container {
  $font-size: 1.16rem;
  $description-content-height: $font-size * 2;
  display: block;
  text-align: left;
  padding: 0 60px 0 0;

  .classes-schedule-table__description-box {
    position: relative;

    .classes-schedule-table__description-title {
      font-family: 'ProximaNova-Bold';
    }

    .classes-schedule-table__description-content {
      font-family: 'Proxima Nova Regular';
      overflow: hidden;
      margin-top: 5px;
      transition: max-height $short-transition-time;

      &.classes-schedule-table__description-content_hidden {
        max-height: $description-content-height;
      }

      &.classes-schedule-table__description-content_show {
        max-height: 300px;
      }
    }

    .classes-schedule-table__description-dots {
      position: absolute;
      top: $description-content-height - .1rem;
      right: -15px;
      transition: $medium-transition-time;

      &.classes-schedule-table__description-dots_visible {
        opacity: 1;
      }

      &.classes-schedule-table__description-dots_hidden {
        transition: $small-transition-time;
        opacity: 0;
      }
    }

    .classes-schedule-table__description-show-button {
      font-family: 'ProximaNova-Bold';
      color: $accent-secondary-color;
      padding: 15px 0 10px;
      cursor: pointer;
      transition: color $short-transition-time;

      &:hover {
        color: darken($accent-secondary-color, 10%);

        .classes-schedule-table__description-button-chevron {
          fill: darken($accent-secondary-color, 10%);
        }
      }

      &.classes-schedule-table__description-show-button_hidden {
        display: none;
      }

      .classes-schedule-table__description-button-chevron {
        fill: $accent-secondary-color;
        transition: transform $short-transition-time, fill $short-transition-time;
        margin-left: 10px;
      }

      .classes-schedule-table__description-button-chevron-up {
        transform: rotateZ(0deg);
      }

      .classes-schedule-table__description-button-chevron-down {
        transform: rotateZ(180deg);
      }
    }
  }
}

.classes-schedule-table__dropdown-menu-container {
  width: 100%;
  height: 100%;

  .classes-schedule-table_featured-class-icon {
    position: relative;
    top: 5px;
  }
}

