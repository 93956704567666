.logo__img {
  width: 100%;
  height: 77px;
  fill: #373e48;
  display: block;

  @include mobile {
    height: 70px;
  }
}

.introduction__logo,
.health__logo,
.vet-info__logo,
.waiver__logo {
  .logo__img {
    height: 55px;

    @include mobile {
      height: 70px;
    }
  }
}

.logo_33 .logo__img {
  height: 33px;
  width: 100px;
}

.logo_white .logo__img {
  fill: white;
}

.logo_customer {
  display: inline-block;

  .logo__img {
    height: 40px;
    width: 120px;
  }
}
