.schedule {
  &__count {
    font-size: 16px;
    font-family: $bold;
    letter-spacing: .6px;
    margin-bottom: 15px;

    @include mobile {
      text-align: center;
    }
  }
  &__item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
