.immunizations-table {
  &__row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.44;
    letter-spacing: .8px;

    &_header {
      font-family: $bold;
      color: $common-gray-color;
    }
  }
  &__col {
    &_checkmark {
      color: #3ba848;
    }
    &_exclamation {
      color: #f1aa00;
    }
    &_cancel {
      color: #f00;
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 2px;
    }
  }
}
