.forms-table-page {
  @extend .dashboard-page;
  min-height: calc(100vh - #{$page-header-min-height});
}

.forms-table-employee {
  .rt-tbody {
    min-height: calc(100vh - 19.5rem);
    max-height: calc(100vh - 19.5rem);

    @media screen and (max-width: 1020px) {
      min-height: calc(100vh - 19.5rem - 16px);
      max-height: calc(100vh - 19.5rem - 16px);
    }

    @include mobile {
      max-height: calc(100vh - 14.5rem - 16px);
    }
  }

  .ReactTable.-with-scroll-bar {
    .rt-tbody {
      min-width: 916px !important;
    }
  }
}

.forms-table-employee > .ReactTable > .rt-table > .rt-tbody {
  @include mobile {
    min-width: 100% !important;
  }
}

.forms-table-employee .forms-table__th.forms-table_no-mobile,
.forms-table-employee .forms-table__td:not(.table__td_last-of-type).forms-table_no-mobile,
.forms-table-employee:not(.marketing-table) .rt-thead.-header {
  @include mobile {
    display: none;
  }
}

.forms-table-page__header {
  @extend %flex-container-rows-centred-end;
  height: 85px;
  @include mobile {
    height: 40px;
  }
}

.forms-table-page__header > .add-new-item {
  @include mobile {
    display: none;
  }
}

.forms-table__form-name {
  justify-content: center;
  padding: 10px 20px !important;
}

.forms-table__recipient-placeholder {
  color: $common-gray-color;
}

.forms-table__td_mobile {
  @extend %table__td;
  position: relative;
  display: none;
  justify-content: flex-start;
  padding: 10px 5px 10px 15px !important;
  @include mobile {
    display: flex;
  }
}

.forms-table__mobile-hidden {
  @include mobile {
    display: none;
  }
}

.forms-table__mobile-cell {
  text-align: left;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.forms-table__mobile-primary {
  @extend %typo-table-primary;
}

.forms-table__mobile-secondary {
  @extend %typo-table-secondary;
}

.forms-table-employee > .ReactTable .rt-tr {
  .icon_chevron_down {
    @include mobile {
      fill: $common-gray-color !important;
    }
  }
}

.forms-table-employee > .ReactTable > .rt-table .table__td_last-of-type {
  @include mobile {
    max-width: 40px !important;
    padding: 10px 5px;
  }
}
