.link-button {
  @extend %typo-link-button;
  @include underline(false, 1px, 100%, $common-gray-color, .3);
  line-height: 1;
  border: none;
  background: transparent;
  color: $common-gray-color;
  transition: color $short-transition-time;
  &:hover {
    @include underline(false, 1px, 100%, $accent-secondary-color, .3);
    color: $accent-secondary-color;
    transition: color $short-transition-time;
  }
}

.link-button_disabled {
  cursor: not-allowed !important;
  &:hover {
    color: $common-gray-color !important;
  }
}
