.payment-methods {
  display: block;

  &__list {
    margin: 0 -20px 15px;
  }

  &__add {
    display: inline-block;
  }
}
