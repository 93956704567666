.sent-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  max-width: 495px;
  margin: 0 auto;
  letter-spacing: .8px;

  &__check {
    width: 66px;
    height: 66px;
    background: $accent-primary-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 23px;
  }

  &__icon {
    margin-bottom: 23px;
  }

  &__icon-check {
    width: 40px;
    height: 40px;
    fill: #fff;
  }

  &__icon-sms {
    width: 73px;
    height: 57px;
    fill: $accent-primary-color;
  }

  &__icon-mail {
    width: 60px;
    height: 38px;
    fill: $accent-primary-color;
  }

  &__title {
    text-transform: uppercase;
    color: $accent-primary-color;
    margin-bottom: 20px;
    font-family: $bold;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__link {
    @extend .link;
    font-family: $bold;
  }
}
