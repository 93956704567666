$dashboard-img-width: 39.02%;
$dashboard-container-width: 100% - $dashboard-img-width;

.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: filter $short-transition-time linear, transform $duration $easeInOutSine;
  will-change: filter, transform;
}

.dashboard_blurred {
  filter: blur(4px);
  transition: filter $short-transition-time linear;
}

.dashboard_mobile-sidebar {
  transform: translateX(calc(-100% + 52px));
  height: 100vh;

  .dashboard__mobile-sidebar-overlay {
    visibility: visible;
  }
}
.dashboard_blocked {
  @include mobile {
    height: 100vh;
    position: fixed;
    width: 100%;
  }
}

.dashboard__mobile-sidebar-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  display: none;

  @include mobile {
    display: block;
  }
}

.dashboard__container {
  @extend %container;
}

.dashboard__main {
  @extend %flex-container-columns;
  min-height: 100%;
  width: calc(100% - #{$page-header-min-height});

  @include mobile {
    width: 100%;
  }
}

.dashboard__header-action {
  position: absolute;
  top: 8.5px;
  right: 12.5px;
  line-height: 0;

  &:hover {
    cursor: pointer;
  }
}

%dashboard__content {
  background-color: $common-white-color;
  height: auto;
  margin: 40px 156px 50px;
  box-shadow: $common-shadow;
  border-radius: 0 0 5px 5px;

  @include tablet {
    margin: 40px 25px 0;
  }

  @include mobile {
    margin-left: 10px;
    margin-right: 10px;
  }
}

%dashboard__list {
  position: relative;
  padding: 1.23rem 2.9rem 2.9rem;
  width: calc(100% - #{$page-header-min-height});
  height: auto;

  @include mobile {
    padding: 20px 10px;
    width: 100%;
  }
}
