.address-header {
  position: relative;

  &__title {
    font-size: 16px;
    font-family: $bold;
    text-transform: uppercase;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 16px;
  }

  &__edit {
    position: absolute;
    width: 38px;
    height: 38px;
    right: 0;
    top: 50%;
    margin-top: -19px;
  }

  &__icon-edit {
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: fill $duration;

    &:hover {
      fill: $accent-secondary-color-hover;
      @extend %faster;
    }
  }
}
