.location-change {
  position: relative;
  background: transparent;

  &__content {
    margin-top: 0;
  }

  &__head {
    max-width: 360px;
    margin: 19px auto 38px;
    font-size: 16px;
    line-height: 1.2em;
    letter-spacing: .8px;
    text-align: center;
    color: $common-dark-gray-color;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__locations-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
  }

  &__radio {
    margin-left: 0;
    margin-bottom: 21px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__content {
      display: flex;
      max-width: calc(100% - 40px);
      flex-direction: column;
      line-height: 1.2em;
      margin: 0;
    }

    &__text {
      align-items: flex-start;
      &:before {
        margin-top: 10px;
        margin-right: 12px !important;
      }
      &:after {
        margin-top: -13px !important;
        left: 10px !important;
        @include mobile {
          margin-top: - 13px !important;
          left: 7px !important;
        }
      }
    }

    &__title, &__address {
      @include mobile {
        font-size: 16px;
      }
    }
  }

  &__add-btn {
    display: inline-flex;
    margin-top: 20px;
  }
}

.location-change__radio__address {
  line-height: 1.2;
  display: block;
}

.location-change__radio__title {
  line-height: 1.2;
}

.location-change__radio {
  margin-bottom: 20px;
  justify-content: flex-start;
  margin-left: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &_disabled .location-change__radio__text {
    color: #98999d;
  }
}

.location-change__add-btn {
  justify-content: flex-start;
}

.location-change__button {
  margin: 0 auto 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.location-change__footer {
  text-align: center;
  margin-top: 20px;
}

.location-change__tooltip {
  color: $accent-secondary-color;
  text-decoration: underline;
  display: inline-block;
  font-size: 14px;
}
