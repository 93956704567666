$waiver-width: 69.44%;
$waiver-width_tablet: 86.98%;
$waiver-logo-width: 29.4%;

.waiver {
  @extend .signin;
}

.waiver__img {
  @extend .signin__img;
  background-image: url('/assets/imgs/slide-4.jpg');
  @include not-desktop {
    display: none;
  }
}

.waiver__content {
  @extend .signin__content;
}

.waiver__container {
  @include onboarding-container($waiver-width, $waiver-width_tablet, $signin-width_mobile);
  min-height: calc(100vh - 22.51rem);
  padding-bottom: 20px;
  box-sizing: border-box;
}

// todo: should it be done like container above?
.waiver__logo {
  @extend .signin__logo;
  width: $waiver-logo-width;

  @include mobile {
    width: $signin-logo-width_mobile;
  }
}

.waiver__step-name {
  @extend .introduction__step-name;
}

.waiver__step-description {
  @extend .vet-info__step-description;
  width: 60%;

  @include tablet {
    width: 38.5%;

    .step-description__arrow {
      margin: 1.59rem 0;
    }

    .step-description__image {
      padding-bottom: 111.615%;
    }
  }

  @include mobile {
    width: 100%;

    .step-description__disclaimer {
      padding: 0;
    }
  }
}

.waiver__step-description-image {
  @extend .step-description__image;
  background-image: url('/assets/imgs/slide-4_tablet.jpg');

  @include mobile {
    padding-bottom: 62.5%;
    background-image: url('/assets/imgs/slide-4_mobile.jpg');
  }
}

.waiver__form {
  @extend %flex-container-columns-around;
  flex: 1;
  position: relative;
  margin-top: $common-margin-top-big;
  min-width: 100%;

  .checkbox-control__text {
    align-items: flex-start;
  }

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: 2.46rem;

    .checkbox-control {
      margin: 2.54rem auto 0;
      width: 80%;
    }

    .checkbox-control__text {
      &:before {
        //margin-top: 0;
      }
    }
  }
}

.waiver__form-legalese {
  position: relative;
  border: 1px solid $common-medium-gray-color;
  border-radius: $common-border-radius;
  padding: 1.45rem $common-margin-large_tablet 1.45rem 1.45rem;
  height: 24.06rem;
  //min-height: 24.06rem;
  //max-height: 24.06rem;
  font-size: 1.16rem;
  letter-spacing: .4px;
  overflow: scroll;

  @include mobile {
    height: 30.06rem;
    padding: 1.16rem;
  }
}

.legalese__text {
  line-height: 1.3;
}

.legalese__text-loader {
  @extend %flex-container-columns-centred;
  height: 100%;
  padding-top: 10rem;
  padding-left: $common-margin-top-big;
}

.waiver__submit-button {
  @extend .signin__submit-button;
  width: 60.05%;

  @include tablet {
    margin-bottom: $common-margin-large_tablet;
    width: 54.05%;
  }

  @include mobile {
    width: 100%;
    margin: 2.46rem 0;
  }
}

.waiver__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}
