* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  @include mobile {
    height: auto;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

html {
  @include typo-font-family('preferred');
  line-height: 1;

  //@media screen and (min-width: $font-adjustment-1) {
  //  font-size: 13px;
  //}
  //
  //@media screen and (min-width: $font-adjustment-2) {
  //  font-size: 10.5px;
  //}
  //
  //@media screen and (min-width: $font-adjustment-3) {
  //  font-size: 11px;
  //}
  //
  //@media screen and (min-width: $font-adjustment-4) {
  //  font-size: 11.5px;
  //}
  //
  //@media screen and (min-width: $font-adjustment-5) {
  //  font-size: 12px;
  //}
  //
  //@media screen and (min-width: $font-adjustment-6) {
  //  font-size: 12.5px;
  //}

  @media screen and (min-width: $font-adjustment-1) {
    font-size: 13px;
  }

  @media screen and (min-width: $font-adjustment-8) {
    font-size: 13.5px;
  }

  @media screen and (min-width: $font-adjustment-9) {
    font-size: 14px;
  }

  @media screen and (min-width: $font-adjustment-10) {
    font-size: 14.5px;
  }

  @media screen and (min-width: $font-adjustment-11) {
    font-size: 15px;
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

button {
  &:hover {
    cursor: pointer;
  }
}

button,
input,
textarea {
  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.hidden {
  visibility: hidden;
}
