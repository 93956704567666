.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.header__nav {
  padding: 1rem;
}
