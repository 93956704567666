.alert-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 0;
}

.alert-wrapper > span {
  position: relative;
}

.alert-message {
  @extend %flex-container-rows-centred;
  position: relative;
  width: 100%;
  min-height: 60px;
  padding: 10px 60px;
  background-color: $common-white-color;
  box-shadow: $common-shadow;
}

.alert-message__icon-check {
  @extend %flex-container-columns-centred;
  min-width: 35px;
  height: 35px;
  background-color: $accent-primary-color;
  border-radius: 50%;
  margin-right: 1rem;

  @include mobile {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .icon {
    fill: #fff;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    opacity: .75;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -100%, 0);
    opacity: .75;
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.alert-enter {
  animation-name: slideInDown;
  animation-duration: .35s;
  animation-fill-mode: both;
  will-change: transform;
}

.alert-leave {
  animation-name: slideOutUp;
  animation-duration: .35s;
  animation-fill-mode: both;
  will-change: transform;
}

.alert-leave-down {
  animation-name: fadeOutDown;
  animation-duration: .35s;
  animation-fill-mode: both;
  will-change: transform;
}
