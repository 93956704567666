.profile-addresses {
  &__list {
    margin: 0 -20px 10px;
  }

  &__item {
    padding: 20px;
    transition: background $duration;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      display: block;
    }

    @include desktop {
      &:hover {
        background: #f8f8f8;
        transition-duration: $duration/2;
      }
    }
  }

  &__content {
    font-size: 16px;
    padding-right: 20px;
  }

  &__title {
    font-family: $bold;
    margin-bottom: 4px;
  }

  &__address {
    display: block;
    margin-bottom: 2px;
  }

  &__set {
    @include mobile {
      margin-bottom: 10px;
    }

    &__divider {
      display: none;
      @include mobile {
        display: block;
        margin-bottom: 2px;
      }
    }

    .link-switcher__item {
      @include mobile {
        display: inline;

        &:after {
          display: none;
        }
      }
    }
  }

  &__add {
    display: inline-block;
  }

  &__actions {
    width: 90px;

    @include mobile {
      width: 100%;
    }
  }

  .change-actions__item {
    @include mobile {
      display: inline-block;
      color: $accent-secondary-color;
      margin-right: 10px;

      svg {
        display: none;
      }
    }
  }

  .content-block__header {
    @include mobile {
      display: block;

      &-text {
        margin-bottom: 10px;
      }
    }
  }
}
