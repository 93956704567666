.payment-item {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  transition: background $duration;

  @include desktop {
    &:hover {
      background: #f8f8f8;
      transition-duration: $duration/2;
    }
  }

  @include mobile {
    display: block;
  }

  &__data {
    flex-grow: 1;
    position: relative;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__number {
    font-family: $bold;
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__date {
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__status {
    font-size: 14px;
    cursor: pointer;
    color: $accent-secondary-color;
    transition: color $duration;
    display: inline-block;

    @include mobile {
      position: absolute;
      left: 75px;
      bottom: 55px;
    }

    &:hover:not(.payment-item__status_default) {
      color: $accent-secondary-color-hover;
      @extend %faster;
    }

    &_default {
      color: $common-dark-gray-color;
      cursor: default;
    }
  }

  &__icon {
    padding-right: 30px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__card-icon {
    width: 60px;
    height: 38px;
    display: block;
  }

  &__actions {
    @include mobile {
      display: flex;
      align-items: center;
      width: 100%;

      .change-actions__item {
        &:first-child {
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
    }
  }
}
