form.template-selector-preview {
  width: 100%;
  pointer-events: none;
  margin: 0 auto;
  overflow: hidden;
}

form.template-selector-preview {
  width: 610px;
  transform: scale(.6) translateX(-206px);
  transform-origin: center top;
}

.template-selector-preview.template3-selector-preview {
  transform: scale(.4) translateX(-309px);
}

.template-selector-preview__logo {
  display: inline-block;
  margin-top: 15px;
  & > svg.logo__img {
    width: 118px;
    height: 39px;
  }
}

.template-selector-preview-container {
  padding: 10px;
  filter: grayscale(100%);
  transition: filter $short-transition-time;
  &:hover {
    filter: grayscale(0);
  }
}

.template-card.template-card_active .template-selector-preview-container {
  filter: grayscale(0);
}

.template-selector-preview-container.template1-selector-preview-container > .template-selector-preview__logo {
  margin-top: 5px;
  margin-bottom: -5px;
}

.template-selector-preview-container.template3-selector-preview-container > .template-selector-preview__logo {
  transform: scale(.65);
  margin-top: 5px;
  margin-bottom: -10px;
}
