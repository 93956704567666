.booking {
  position: relative;
  display: flex;

  &__container {
    @extend .purchase-package__container;
    &_single {
      width: 100%;
      max-width: 700px;
    }
  }

  &__block {
    @extend .purchase-package__block;
    &_main {
      max-width: 580px;
      @include tablet {
        max-width: 385px;
      }
      @include mobile {
        max-width: 300px;
        margin: 0 auto 20px;
      }
      &:last-child {
        max-width: 700px;
      }
    }

    &_summary {
      max-width: 300px;
      @include mobile {
        margin: 0 auto;
      }
    }
  }
}
