.pickup-location {
  position: relative;

  &__head {
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.4em;
    letter-spacing: .8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__head-title {
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $common-dark-gray-color;
    &:last-child {
      margin-bottom: 0;
    }

    span {
      color: $accent-primary-color;
    }
  }

  &__head-subline {
    font-size: 16px;
    @include tablet {
      margin: 0 20px;
    }
    .tooltip {
      cursor: pointer;
      font-size: 14px;
      color: $accent-secondary-color;
      display: block;
    }
  }

  &__checkbox-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__invalid-zip {
    font-size: 14px;
    .tooltip {
      cursor: pointer;
      font-size: 14px;
      color: $accent-secondary-color;
    }
  }
}
