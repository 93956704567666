.delete-item {
  @extend %flex-container-rows-centred-start;

  &:hover {
    cursor: pointer;
  }
}

.delete-item__text {
  @extend %typo-add-new-item;
  color: $common-gray-color;
  margin-left: 10px;

  @include mobile {
    display: none;
  }
}

.delete-item__icon {
  position: relative;
  background-color: $common-gray-color;
  width: 22px;
  height: 22px;
  border-radius: 50%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: white;
    transition: background-color $short-transition-time;
  }

  &:before {
    top: 50%;
    margin-top: -1px;
    left: 5px;
    width: 12px;
    height: 2px;
  }
}
