.welcome {
  position: relative;
  display: flex;
  min-height: calc(100vh - 60px);
  height: 100%;
  justify-content: space-between;
  background: #fff;

  @include not-desktop {
    flex-wrap: wrap;
    min-height: calc(100vh - 120px);
  }

  &__img {
    width: 39.06%;
    max-width: 800px;
    min-height: 100%;
    background-position: center;
    background-size: cover;

    @include tablet {
      height: 375px;
      width: 100%;
      max-width: 100%;
    }

    @include mobile {
      width: 100%;
      max-width: 100%;
      height: 215px;
      background-size: 100%;
    }
  }
  &__content {
    width: calc(100% - 400px);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 40px 30px;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__wrapper {
    &_small {
      max-width: 390px;
    }

    &_scheduling {
      max-width: 460px;
      @include tablet {
        max-width: 560px;
      }
    }
  }

  &__title {
    font-family: $bold;
    font-size: 45px;
    letter-spacing: 3.2px;
    color: $accent-primary-color;
    line-height: 1em;
    margin: 0 0 20px;
    text-transform: uppercase;
    @include mobile {
      font-size: 35px;
      line-height: 1.2em;
      letter-spacing: 2.5px;
      text-align: center;
    }
    &_centered {
      text-align: center;
    }
    &_scheduling {
      font-size: 35px;
      line-height: 1.2em;
      text-align: center;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.3em;
    letter-spacing: .8px;
    &_centered {
      text-align: center;
    }
    h5 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.3em;
      margin: 35px 0 13px;
    }

    ul {
      padding-left: 15px;
      li {
        margin-bottom: 18px;
      }
    }
  }

  &__button {
    width: 150px;
    margin-top: 40px;
    &_final {
      padding: 0 20px;
      display: table;
      width: auto;
    }
  }
}
