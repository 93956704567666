.main-page {
  height: calc(100vh - 60px);
  min-height: 600px;

  @include not-desktop {
    min-height: calc(100vh - 120px);
  }

  @include tablet {
    height: auto;
    min-height: calc(100vh - 60px);
  }
  @extend %flex-container-columns;

  @include tablet {
    height: auto;
  }
  @include mobile {
    height: auto;
  }

  &__featured-slider {
    height: calc(100vh - 330px);
    border-bottom: 5px solid $accent-primary-color;

    @include tablet {
      height: 450px;
    }
    @include mobile {
      height: auto;
    }
  }

  &__info-list {
    height: 330px;
    background: #fff;
    @extend %flex-container;

    @include tablet {
      height: auto;
      flex-direction: column;
    }
    @include mobile {
      height: auto;
      flex-direction: column;
    }
  }

  &__info-block {
    width: 33.3333%;
    min-height: 250px;
    border-right: 1px solid #dddddc;

    @include tablet {
      width: 100%;
      min-height: auto;
      border-bottom: 1px solid #dddddc;
      border-right: none;
    }

    @include mobile {
      width: 100%;
      min-height: auto;
      border-bottom: 1px solid #dddddc;
      border-right: none;
    }

    &:last-child {
      border: none;
    }
  }
}
