.payment-change {
  position: relative;

  &__content {
    margin-top: 0;
  }

  &__head {
    max-width: 360px;
    margin: 19px auto 38px;
    font-size: 16px;
    line-height: 1.2em;
    letter-spacing: .8px;
    text-align: center;
    color: $common-dark-gray-color;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__locations-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
  }

  &__radio {
    margin-left: 0;
    margin-bottom: 21px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__content {
      line-height: 1.2em;
      margin: 0;
    }

    &__text {
      &:before {
        margin-right: 12px !important;
      }
    }

    &__title, &__address {
      @include mobile {
        font-size: 16px;
      }
    }
  }

  &__add-btn {
    display: inline-flex;
  }
}
