.confirmation {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px 46px 0;
  overflow: hidden;
  @include mobile {
    margin-left: 25px;
    margin-right: 25px;
  }

  &__logo {
    width: 66px;
    height: 66px;
    margin-bottom: 25px;
  }

  &__title {
    margin: 0 0 5px;
    font: 20px 'Proxima Nova Semibold';
    color: $common-dark-gray-color;
  }

  &__text {
    max-width: 450px;
    margin: 0 55px 20px;
    font-size: 16px;
    line-height: 1.3em;
    letter-spacing: .8px;
    color: $common-dark-gray-color;
    text-align: center;
    @include mobile {
      margin-left: 0;
      margin-right: 0;
    }
    span {
      font-weight: bold;
      font-family: $bold;
    }
  }

  &__subline {
    margin: 0 auto 35px;
    font-size: 14px;
    max-width: 450px;
    line-height: 1.3em;
    text-align: center;
    color: $common-gray-color;
  }

  &__nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;
    @include mobile {
      flex-wrap: wrap;
    }
    &__item {
      width: calc(50% - 10px);
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      border: 1px solid #ddd;
      font-size: 16px;
      font-weight: bold;
      font-family: $bold;
      color: $common-dark-gray-color;
      fill: $common-dark-gray-color;
      transition: border-color $duration, background-color $duration, color $duration, fill $duration;
      @include mobile {
        margin-bottom: 20px;
        width: 100%;
        height: 150px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      span {
        margin-bottom: 25px;
      }
      &:hover {
        transition-duration: $duration/2;
        border-color: $accent-secondary-color;
        background-color: $accent-secondary-color;
        color: #fff;
        fill: #fff;
      }
    }

    &__icon {
      width: 85px;
      height: 85px;
      margin-top: 10px;

      &_schedule {
        width: 48px;
      }
    }
  }

  &__maps-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    @include mobile {
      flex-wrap: wrap;
    }
  }
  &__map {
    width: calc(50% - 10px);
    @include mobile {
      width: 100%;
      margin-bottom: 37px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__wrapper {
      width: 100%;
      max-width: 295px;
      height: 205px;
      position: relative;
      margin-bottom: 30px;
      @include mobile {
        display: none;
      }
    }

    &__content {
      overflow: hidden;
      position: relative;
      text-align: center;
      font-size: 16px;
    }

    &__title {
      margin: 0 0 7px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $bold;
      letter-spacing: .8px;
      &_pickup {
        color: $accent-primary-color;
      }
      &_dropoff {
        color: $accent-secondary-color;
      }
    }

    &__name {
      margin: 0 0 4px;
      font-weight: bold;
    }

    &__address {
      margin: 0;
      line-height: 1.2em;
    }

    &__city {
      margin: 0;
      line-height: 1.2em;
    }
  }
}
