.step-name__text {
  @extend %typo-step-name;
  color: $accent-primary-color;
  text-align: center;
}

.step-name__test-number {
  &:after {
    content: '-';
    display: inline-block;
    margin: 0 1rem;

    @include mobile {
      display: none;
    }
  }

  @include mobile {
    color: $common-black-color;
    text-align: center;
  }
}

.step-name__test-name {
  @include mobile {
    display: block;
    margin-top: .5rem;
  }
}
