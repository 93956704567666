.modal-dialog {
  @extend %flex-container-columns-centred;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  visibility: hidden;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .4);
  opacity: 0;
  color: $common-dark-gray-color;
  transition: opacity $short-transition-time, visibility $short-transition-time;

  .Select-arrow-zone {
    &:hover {
      .Select-arrow {
        &:before {
          border-color: $common-dark-gray-color;
        }
      }
    }
  }

  .select-control__select.is-open {
    .Select-arrow {
      &:before {
        border-color: $accent-primary-color;
      }
    }
  }

  .select-control__select .Select-arrow {
    background: none !important;

    &:before {
      content: '';
      width: 9px;
      height: 9px;
      border: 2px solid #ddd;
      border-width: 0 2px 2px 0;
      display: block;
      transform: rotate(45deg);
      position: absolute;
      bottom: 2px;
      left: 2px;
      transition: border-color $short-transition-time;
    }
  }
}

.modal-dialog_active {
  visibility: visible;
  opacity: 1;
}

.modal-dialog__content {
  width: 650px;
  max-width: 650px;
  margin-left: 10px;
  margin-right: 10px;
  max-height: 90%;
  overflow: auto;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, .36);
  background-color: $common-white-color;
  border-radius: 3px;
  transform: translateX(20%);
  transition: transform .3s;

  @include mobile {
    width: 100%;
  }
}

.modal-dialog__cancel {
  font-size: 16px;
  color: $accent-secondary-color;
  font-family: $bold;
  transition: color $duration;
  cursor: pointer;
  margin-top: 20px;

  &:first-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $accent-secondary-color-hover;
    @extend %faster;
  }
}

.modal-dialog_active .modal-dialog__content {
  transform: translateX(0);
}

.modal-dialog__content_overflow-visible {
  overflow: visible;
}

.modal-dialog__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: $common-dark-gray-color;
}

.modal-dialog__header:before {
  content: "";
  width: 21px;
  height: 21px;
  padding-left: 25px;
  visibility: hidden;
}

.modal-dialog__header_transparent {
  background-color: transparent;
  padding: 26px 0 18px;
  height: auto;
  position: relative;
  &:before {
    display: none;
  }
  .modal-dialog__title {
    position: relative;
    padding-bottom: 20px;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: $accent-primary-color;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
    }
  }
  .modal-dialog__content {
    margin-top: 30px;
  }
  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.modal-dialog__header_training-success {
  background-color: transparent;
}

.modal-dialog__header_training-success .modal-dialog__close {
  background: none;
  margin-right: 15px;
  svg {
    fill: $common-gray-color;
  }
  &:hover svg {
    fill: $common-dark-gray-color;
  }
}

.modal-dialog__title {
  flex-grow: 1;
  text-align: center;
  @extend %typo-modal-dialog;
  margin-right: 15px;
}

.modal-dialog__header_transparent {

  .modal-dialog__title {
    color: $common-dark-gray-color;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Proxima Nova Regular';
    margin-right: 0;

    @include mobile {
      margin-right: 0;
      white-space: normal;
      line-height: 1.3em;
    }
  }

  .modal-dialog__close {
    position: absolute;
    right: 15px;
    top: 15px;
    margin-right: 0;
    background-color: transparent;

    &:hover {
      .icon_remove_21 {
        fill: #000;
        @extend %faster;
      }
    }

    .icon_remove_21 {
      fill: $common-gray-color;
      transition: fill $duration;
    }
  }
}

.modal-dialog__close {
  display: flex;
  margin-right: 25px;
  border: none;
  background-color: #373e48;
}

.modal-dialog__form {
  background: #fff;
  .input-control__input {
    &:not(:placeholder-shown) {
      padding: $touched-input-padding;
    }

    &::placeholder {
      color: transparent;
    }
  }

  .checkbox-control__input + .checkbox-control__text {
    @extend %typo-modal-text;
    font-weight: 600;
  }

  .select-control__select .Select-control:not(:placeholder-shown) {
    padding: 0 .8rem !important;
  }

  .input-control__hint {
    width: 64%;
    margin: 10px auto 0;
    color: $common-dark-gray-color;
    text-align: center;
    line-height: 1.3;

    @media not-desktop {
      font-size: .87rem;
    }
  }
}

.modal-dialog__form-content {
  width: 515px;
  max-width: 100%;
  margin: 50px auto 0;
  padding-left: 20px;
  padding-right: 20px;

  // create new employee modal dialog
  .input-control__textarea {
    textarea {
      min-height: 8.81rem;
    }
  }

  @include mobile {
    width: auto;
    margin-top: 32px;
    overflow: visible;
  }
}

.modal-dialog__form-header {
  @extend %flex-container-rows-centred-between;
}

.modal-dialog__form-footer {
  margin-top: 50px;
  padding: 15px 0 20px;
  border-top: solid 1px $common-medium-gray-color_accent;
  align-items: center;
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-top: 32px;
  }
}

.modal-dialog__form-footer_row {
  @extend %flex-container-rows-centred;

  @include mobile {
    flex-direction: column;
  }
}

.modal-dialog__form-footer_add-card {
  padding: 20px 0;
}

.modal-dialog__form-footer_column {
  @extend %flex-container-columns-centred;
}

.modal-dialog__form-footer_column > button:not(:first-child) {
  margin-top: 12px;
}

.modal-dialog__form-footer_booking {
  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.modal-dialog__form-divider {
  @extend %divider;

  @include mobile {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}

.modal-dialog__form-title {
  @extend %typo-modal-dialog-form-title;
}

.modal-dialog__form-title_empty {
  text-align: center;
}

.modal-dialog__button {
  min-width: 165px;
  width: auto;
  padding: 0 1rem;
  margin: 0;

  &:only-child {
    margin: 1.81rem auto 0;
  }

  @include mobile {
    margin: 10px 10px 0;
  }
}

.modal-dialog__button_flexible {
  height: auto !important;
  min-height: 44px;
  line-height: 1.3em;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-dialog__button_secondary {
  border-color: $common-dark-gray-color;
  background-color: $common-dark-gray-color;
  box-shadow: 0 0 0 4px $common-dark-gray-color inset;

  &:hover {
    color: $common-dark-gray-color;
  }
}

.modal-dialog__button_customer-order {
  width: 230px;
  @include mobile {
    width: 230px !important;
  }
}

.modal-dialog__button_report {
  width: 230px;
  margin-top: 0 !important;
  @include mobile {
    width: 230px !important;
  }
}

.modal-dialog__button_booking {
  width: auto;
  max-width: 100%;
}

.modal-dialog__button_sms {
  height: 44px;
  line-height: 44px;
  max-width: 230px;
}

.modal-dialog__button_fee {
  min-width: 330px !important;

  @include mobile {
    min-width: initial !important;
  }
}

.modal-dialog__button_booking {
  width: 300px;
  @include mobile {
    width: 275px;
  }
}

.modal-dialog__button_add-card {
  width: 285px;
  @include mobile {
    width: 230px;
  }
}

.modal-dialog__add-button {
  width: auto;
  padding: 0 20px;
  margin: 0 15px 0 0;
}

.modal-dialog__button,
.modal-dialog__add-button {
  height: 44px;

  @include mobile {
    width: 93.75%;
    margin: 0 10px;
    padding: 0;

    &:last-of-type {
      margin-top: 10px;
    }
  }
}

.modal-dialog__description {
  width: 475px;
  margin: 23px auto;

  .modal-dialog__text {
    margin: 0 !important;
    line-height: 1.4;
  }
}

.modal-dialog__text {
  margin: 20px;
  text-align: center;
  @extend %typo-modal-text;
}

.modal-dialog__text:first-of-type {
  margin-top: 50px;
}

.modal-dialog__text:last-of-type {
  margin-bottom: 50px;
}

.modal-dialog__text_bold {
  font-weight: 600;
}

// hack to display orange labels inside inputs when there is no value
.modal-dialog__form-content {
  .input-control__placeholder {
    opacity: 1;
    color: $accent-primary-color;
    z-index: 2;
    transition: opacity .3s;
  }

  .Select-control {
    .Select-placeholder {
      color: transparent !important;
    }
    .Select-input input {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 1.5rem;
    }
  }
}

.modal-dialog__form_customer-phone {
  .Select-menu {
    position: relative;
    @include mobile {
      min-width: 100%;
    }
  }
}

.modal-dialog__form_dog-creation {
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 0;
  }

  .select-control__is_intoleranced {
    .Select {
      z-index: 1;
    }

    .select-control__select .Select-control {
      padding-top: 1.5rem !important;
    }

    .Select-control {
      display: flex;
      min-height: 5.5rem;
      height: auto;
      padding-top: 1.5rem;

      .Select-value {
        line-height: 2 !important;
      }

      .Select-multi-value-wrapper {
        display: flex;
        align-items: flex-end;
        flex-flow: row wrap;
        margin-bottom: .5rem;

        .Select-input ~ .Select-value {
          background-color: red;
        }

        @include mobile {
          width: 100%;
        }
      }

      .Select-placeholder {
        line-height: 3.5;

        @include mobile {
          line-height: 4.5;
          color: transparent;

          &:before {
            content: 'Start typing...';
            color: #ddd !important;
            font-size: 1.27rem;
            font-weight: normal;
          }
        }
      }
    }

    .Select-arrow-zone,
    .Select-menu-outer {
      display: none;
    }

    .Select--multi {
      .Select-input {
        display: flex !important;
        align-items: center;
        margin-left: 0;
        height: $common-margin-top-large_mobile;
        overflow: hidden;

        input {
          padding-left: 0;
          line-height: 1;
          overflow: hidden;
        }

        @include mobile {
          width: 100%;
          order: 1;

          input {
            width: 100% !important;
            border-bottom: 1px solid $common-medium-gray-color;
            padding-bottom: 8px;
            font-size: 1.34rem;
            font-family: 'Proxima Nova SemiBold';
            color: $common-dark-gray-color;
          }
        }
      }

      .Select-value {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        background-color: transparent;
        border-radius: 40px / 100%;
        border: none;
        color: $common-dark-gray-color;
        font-size: 1.34rem;
        letter-spacing: .5px;
        padding: 0 .1rem 0 0 !important;
        margin-left: 0;
        margin-right: .5rem;
        vertical-align: middle;
        @extend %select-value-transition;

        @include mobile {
          justify-content: space-between;
          order: 2;
          width: 100%;
          margin-right: 0;

          &:first-of-type {
            margin-top: 0;
          }

          &:after {
            display: none !important;
          }

          .Select-value-icon {
            display: block !important;
            opacity: .3 !important;
          }

          &:hover {
            margin-left: 0;
            padding: 0 !important;
            background-color: transparent;
            color: $common-dark-gray-color;

            &:after {
              color: $common-dark-gray-color;
              right: -.7rem;
            }

            ~ .Select-value {
              margin-left: 0;
            }

            + .Select-input {
              margin-left: 0;
            }

            .Select-value-label {
              padding: 2px 5px;
              transition: padding $medium-transition-time;
            }
          }
        }

        &:after {
          content: ',';
          display: inline-block;
          left: -12px;
          bottom: 0;
        }

        &:first-of-type {
          margin-left: 0 !important;
        }

        // #todo: HOW TO REMOVE THIS LAST ','...
        &:last-child {
          background-color: yellow;

          &:after {
            display: none;
          }
        }

        &:hover {
          margin-left: 5px;
          padding: $input-value-padding !important;
          background-color: $common-dark-gray-color;
          color: $common-white-color;
          @extend %select-value-transition;

          &:after {
            color: $common-dark-gray-color;
            right: -.7rem;
          }

          ~ .Select-value {
            margin-left: 1rem;
          }

          + .Select-input {
            margin-left: .95rem;
          }

          .Select-value-icon {
            display: block;
            opacity: .5;
            transition: opacity $short-transition-time;
          }

          .Select-value-label {
            padding: 2px 5px;
            transition: padding $medium-transition-time;
          }
        }

        .Select-value-label {
          padding-left: 0;
          transition: padding $medium-transition-time;
        }

        + .Select-input {
          margin-left: 0;

          @include mobile {
            margin-left: 0;
          }

          input {
            padding: 0;
            line-height: 1.9;
          }
        }

        .Select-value-icon {
          display: none;
          order: 2;
          opacity: 0;
          border: none;
          transition: opacity $short-transition-time;

          &:hover {
            opacity: 1;
            background-color: transparent;
            color: $common-white-color;
            transition: opacity $short-transition-time;
          }

          @include mobile {
            padding: 4px 5px 0;
          }
        }
      }
    }

    .react-select-2--backspace-remove-message {
      display: none;
    }
  }

  .no-results-message {
    color: $warning-color-not-desktop;
  }

  // long text placeholder case, see create new dog modal
  // Select select-control__select select-control__select_touched select-control__select_autocomplete Select--single is-clearable is-searchable has-value
  // Select select-control__select select-control__select_creatable Select--multi is-searchable has-value
  @include mobile {
    .Select--single {
      .Select-input {
        padding-top: 25px !important;
      }

      .Select-value {
        line-height: 4.4 !important;
      }

      ~ .select-control__placeholder {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
      }
    }
  }
}

.modal-dialog__form_edit-class {
  .modal-dialog__form-content {
    margin-top: 33px;
  }
}

.modal-dialog__tab-controls {
  @extend %flex-container-rows-centred;
  height: 21px;
  margin-bottom: 33px;

  @include mobile {
    flex-flow: column nowrap;
  }
}

.modal-dialog__tab-switch {
  @extend %typo-modal-dialog-tab-name;
  @include underline(false, 1px, 100%, $accent-secondary-color, .3);
  color: $accent-secondary-color;
  display: inline-block;
  vertical-align: sub;
}

.modal-dialog__tab-switch_active {
  @extend %typo-modal-dialog-tab-name;
  color: inherit;
  display: inline-block;
  vertical-align: sub;
}

.modal-dialog__tab-switch-wrapper {
  height: 21px;
  margin-right: 17px;
  &:not(:last-child) {
    margin-right: 17px;

    @include mobile {
      margin-right: 0;
    }
  }
  &:not(:first-child) {
    margin-left: 17px;

    @include mobile {
      margin-left: 0;
      margin-right: 0;
      margin-top: 10px;
    }
  }
  &:not(:last-child):after {
    margin-left: 17px;
    height: 21px;
    position: absolute;
    content: ' ';
    display: inline;
    border-left: 1px solid $common-dark-gray-color;

    @include mobile {
      display: none;
    }
  }
}

.modal-dialog__form-block {
  &:last-of-type {
    .modal-dialog__form-divider {
      display: none;
    }
  }
}

.modal-dialog__form_medical-notes {
  border: 1px solid $common-medium-gray-color;
  border-radius: $common-border-radius;
  textarea {
    min-height: 8.81rem;
    min-width: 100%;
    margin-top: 1.81rem;
    border: none;
    padding-top: 0 !important;

    &:focus,
    &:active {
      padding-top: 0 !important;
    }

    @include mobile {
      margin-top: 2.1rem;
      min-height: 13.87rem;
      white-space: pre-wrap;

      ~ .input-control__placeholder {
        opacity: 1;
        color: $accent-primary-color;
        z-index: 2;
      }

      &::placeholder {
        color: transparent;
      }
    }
  }
}

.modal-dialog__form_radio-tabs {
  .radio-control__description {
    margin-bottom: 10px;
  }

  .radio-control__description-label {
    color: $accent-primary-color;
    text-transform: uppercase;
    font-size: 10px;
  }

  @include mobile {
    .radio-control__controls {
      margin-top: 10px;
      flex-flow: column nowrap;
    }

    .radio-control__controls-label {
      margin-left: 0;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .radio-control__description-label {
      text-align: left;
    }
  }
}

.modal-dialog__success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-dialog__success-logo {
  width: 66px;
  height: 66px;
  margin-bottom: 25px;
}

.modal-dialog__success-title {
  margin: 0 0 5px;
  font: 20px 'Proxima Nova Semibold';
  color: $common-dark-gray-color;
}

.modal-dialog__success-text {
  margin: 0 55px 40px;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: .8px;
  color: $common-dark-gray-color;
  text-align: center;
}

.modal-dialog__success-nav {
  display: flex;
  width: auto;
  margin-bottom: 50px;
}

.modal-dialog__success-nav__item {
  width: 210px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin-right: 18px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
  color: $common-dark-gray-color;
  fill: $common-dark-gray-color;
  transition: border-color $duration, background-color $duration, color $duration, fill $duration;
  span {
    margin-bottom: 25px;
  }
  &:hover {
    transition-duration: $duration/2;
    border-color: $accent-secondary-color;
    background-color: $accent-secondary-color;
    color: #fff;
    fill: #fff;
  }
}

.modal-dialog__success-nav__icon {
  width: 85px;
  height: 85px;
  margin-top: 10px;
}

.modal-dialog__cancel-button {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: $accent-secondary-color;
  letter-spacing: .6px;
  transition: color $duration;
  line-height: 1em;
  width: 230px;
  text-align: center;
  margin-top: 20px;
  &:hover {
    @extend %faster;
    color: darken($accent-secondary-color, 20%);
  }
}

.modal-dialog__checkbox-container {
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.modal-dialog__purchase-checkbox {
  margin: 0 0 14px;
  &:last-child {
    margin-bottom: 0;
  }
  .checkbox-control__text {
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: .4px !important;
    padding-left: 30px !important;

    @include mobile {
      &:before {
        width: 16px;
        height: 16px;
        line-height: 16px;
        margin-top: -8px;
      }
    }
  }
  .checkbox-control__input:checked + .checkbox-control__text:after {
    width: 11px !important;
    height: 10px !important;
    margin-top: -5px;
    left: 3px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

// sidebar
.modal-dialog_side-bar {
  overflow: hidden;
  opacity: 1;
  background: none;
  top: 0;
  transition: visibility $duration;
  z-index: 90;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($common-dark-gray-color, .8);
    opacity: 0;
    transition: opacity $duration;
  }

  .modal-dialog {
    &__content {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: 540px;
      margin-right: 0;
      max-height: 100%;
      border-radius: 0;
      transform: translateX(100%);
      transition: transform $duration $easeInCubic;
      padding-top: 60px;
      box-shadow: none;
      padding-bottom: 50px;

      @include not-desktop {
        padding-top: 120px;
      }
      @include mobile {
        width: 100%;
      }
    }

    &__header {
      background: none;
      width: 515px;
      max-width: 100%;
      margin: 0 auto;
      padding: 40px 20px 0;
      height: auto;

      @include mobile {
        padding-top: 50px;
        position: relative;
      }
    }

    &__title {
      color: $common-dark-gray-color;
      text-align: left;
      font-size: 45px;
      padding-left: .05em;
      white-space: normal;

      @include mobile {
        margin-right: 0;
      }
    }

    &__header:before {
      display: none;
    }

    &__close {
      margin-right: 0;
      background: none;

      @include mobile {
        position: absolute;
        right: 20px;
        top: 20px;
      }

      svg {
        fill: $common-gray-color;
        transition: fill $duration;

        &:hover {
          fill: $common-dark-gray-color;
          @extend %faster;
        }
      }
    }

    &__form-content {
      margin-top: 30px;
      width: 100%;
      padding: 0 30px;

      @include mobile {
        padding: 0 20px;
      }
    }
  }

  @include mobile {
    .form__select-aligner {
      margin-top: 2.1rem;
    }

    .radio-control__description-label {
      text-align: left;
    }
  }

  &.modal-dialog_active {
    &:before {
      transition: opacity $duration $easeOutCubic;
      opacity: 1;
    }

    .modal-dialog {
      &__content {
        transform: translateX(0%);
        transition: transform $duration $easeOutCubic;
      }
    }
  }

  .form__separator {
    width: auto;
    background-color: $common-medium-gray-color_accent;
    margin: 27px -30px;
  }

  .radio-control__description-label {
    font-family: $preferred_font;
  }
}

.modal-dialog__section {
  border-top: 1px solid $common-medium-gray-color_accent;
  padding: 30px;

  &:last-child {
    border-bottom: 1px solid $common-medium-gray-color_accent;
  }
}

.modal-dialog__section-title {
  @extend .form__section-title;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal-dialog__section-text {
  font-size: 18.5px;
  line-height: 1.3;
  letter-spacing: .5px;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal-dialog__delete {
  @extend .link;
  font-size: 16px;
  font-family: $bold;
  display: inline-block;
}

.modal-dialog__headline {
  text-align: center;
  padding: 0 30px;
  max-width: 600px;
  margin: 0 auto 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    font-size: 20px;
    font-family: $bold;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  &-subtitle {
    line-height: 1.2;
    font-size: 16px;
  }
}

.modal-dialog__uploading {
  padding: 0 90px 50px;

  @include mobile {
    padding: 0 30px 30px;
  }
}

.modal-dialog_upload {
  .modal-dialog__content {
    right: 0;
    position: absolute;
    margin: 0;
    bottom: 0;
    max-width: 100%;
  }
}

.modal-dialog__body {
  padding: 30px 20px;
  width: 515px;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}

.modal-dialog__form_dogs-evaluation {
  // long text placeholder case, see edit evaluation modal
  @include mobile {
    .input-control,
    .select-control {
      .Select-input {
        padding-top: 25px !important;
      }

      .Select-value {
        line-height: 4.4 !important;
      }

      .input-control__input {
        padding-top: 2.3rem;
      }

      .input-control__placeholder,
      .select-control__placeholder {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
      }
    }
  }
}

.modal-dialog__ios-scroll-fix {
  @supports (-webkit-overflow-scrolling: touch) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
