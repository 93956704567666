.dog-details {
  &__slider-card {
    @extend .content-block;
    overflow: hidden;
  }

  &__body {
    @extend .inner-page__body;
  }

  &__sidebar {
    @extend .inner-page__sidebar;
  }

  &__content {
    @extend .inner-page__content;
  }

  &__cards {
    .dog-report__card {
      padding: 0;
      max-width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    @include tablet {
      .dog-report__image-container {
        display: flex;
        flex-direction: column;
        height: auto;
      }
      .dog-report__image {
        position: relative;
      }
      .dog-report__icons {
        flex-direction: column;
        position: relative;
        height: $mobile-icons-height;
        min-height: $mobile-icons-height;
      }
      .dog-report__icon-container .dog-report__icon-view-column {
        width: 50px;
      }
      .dog-report__icon-container .dog-report__icon-description-column {
        flex-direction: row;
        width: 100%;
      }
      .dog-report__icon-container .dog-report__icon-view-column .dog-report__icon-view {
        margin: 0 auto;
      }
      .dog-report__icon-container .dog-report__icon-description-column .dog-report__icon-label-row {
        margin-left: auto;
        flex: 0 0 0;
        margin-right: 10px;
      }
    }
  }

  &__access-level {
    color: $accent-health-color;
  }
}
