.purchase-package {
  position: relative;

  &__container {
    padding-top: 50px;
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include tablet {
      max-width: 700px;
    }
    @include mobile {
      display: block;
    }
  }

  &__expiration-block {
    @include tablet {
      margin-top: 0;
      & > div {
        width: 100%;
      }
    }
  }

  &__block {
    border-radius: 3px;
    box-shadow: 0 0 9px rgba(0, 0, 0, .06);
    background: #fff;
    border: solid 1px #dddddc;
    width: 100%;
    &_main {
      max-width: 580px;
      @include tablet {
        max-width: 385px;
      }
      @include mobile {
        max-width: 300px;
        margin: 0 auto 20px;
      }
    }

    &_summary {
      max-width: 300px;
      @include mobile {
        margin: 0 auto;
      }
    }
  }

  &__confirmation {
    margin-top: 65px;
  }

  &__confirmation-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
    overflow: hidden;
    margin: 0 auto;
    @include tablet {
      padding: 0 36px;
    }
    @include mobile {
      padding: 0 20px;
    }
  }

  &__text-block {
    color: $common-dark-gray-color;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.2em;
    display: flex;
    width: 100%;
    margin: 0 0 52px;
    @include not-desktop {
      flex-wrap: wrap;
      margin-bottom: 32px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__title {
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      width: 75px;
      margin-right: 25px;
      @include not-desktop {
        width: 100%;
        margin-bottom: 5px;
        margin-right: 0;
      }
    }
    &__content {
      display: inline-block;
      vertical-align: top;
      max-width: calc(100% - 100px);
      @include not-desktop {
        max-width: 100%;
      }
    }
  }

  &__field-container {
    margin-top: -20px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    width: auto !important;
  }
}
