.filter-tags {
  @extend %flex-container-rows-wrapped-centred;

  @include mobile {
    max-width: 100vw;
  }

  &__list {
    @include mobile {
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll;
    }
  }
  &__container {
    text-align: center;
  }
}

.filter-tag {
  @extend %typo-filter-tag;
  padding: 12px 10px 12px 15px;
  display: inline-block;
  color: $common-white-color;
  background-color: $common-dark-gray-color;
  border-radius: 40px;

  @include mobile {
    white-space: nowrap !important;
    display: flex;
    align-items: center;

    .filter-tag__text {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
}

.filter-tag-remove {
  fill: $common-white-color;
  opacity: .5;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
}

.filter-tags__link-button {
  margin-top: 12px;
}
