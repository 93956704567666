// .rbc-event is changed in event.less

$calendar-border-value: 1px solid $calendar-border-gray-color;

.calendar {
  @extend .directory;
}

.calendar__loading-text {
  font-size: 16px;
}

.calendar__loading-btn {
  margin-top: 0;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.calendar__top {
  @extend .directory__top;

  // fake toolbar to display while events are loading
  ~ .rbc-toolbar {
    height: 0;

    .rbc-btn-group:first-of-type {
      top: 17px;

      button:disabled {
        background-color: $common-medium-gray-color_accent;

        &:hover {
          background-color: $common-medium-gray-color_accent;
          border-color: $common-medium-gray-color_accent;
          color: $common-dark-gray-color;
        }
      }
    }
  }

  @include mobile {
    .select-control,
    .Select-control {
      width: 220px;
    }
  }

  @include tablet {
    .select-control,
    .Select-control {
      width: 150px;
    }
  }
}

.calendar__add-new {
  @extend .directory__add-new;
}

.calendar__view {
  padding-top: 58px;
  border-top: 1px solid $common-medium-gray-color_accent;
  height: calc(100vh - 191px);

  @include mobile {
    padding-top: 0;
  }
}

.calendar__header {
  @extend %table__header;
  box-shadow: $common-shadow;
}

.calendar__loading {
  @extend %flex-container-columns-centred;
  height: calc(100vh - 290px);
  background-color: $common-white-color;
}

.calendar__loader {
  text-align: center;

  .icon_loader {
    width: 50px;
    height: 50px;
  }
}

.rbc-calendar {
  height: 92.7%;
  box-shadow: $common-shadow;
}

// overriding rbc toolbar
.rbc-toolbar {
  position: relative;
  justify-content: center;
  height: 50px;
  margin-bottom: 0;
  background-color: $calendar-background-gray-color;

  .rbc-toolbar-label {
    @extend %typo-calendar-heading;
    color: $common-dark-gray-color;
    width: auto;
    height: 18px;
    padding: 0 30px;

    &:hover {
      cursor: pointer;
    }
  }

  button {
    @extend %typo-input-top-placeholder;
    height: 100%;
    padding: .6rem 1rem;
    border-color: $common-medium-gray-color_accent;
    color: $common-dark-gray-color;

    &:active,
    &:focus,
    &.rbc-active {
      background-image: none;
      box-shadow: none;
      background-color: $common-dark-gray-color;
      border-color: $common-dark-gray-color;
      color: $common-white-color;
      transition: background-color $short-transition-time, color $short-transition-time, border-color $short-transition-time;

      &:hover,
      &:focus {
        background-color: $common-dark-gray-color;
        border-color: $common-dark-gray-color;
        color: $common-white-color;
        transition: background-color $short-transition-time, color $short-transition-time, border-color $short-transition-time;
      }
    }

    &:hover {
      background-color: $common-dark-gray-color;
      border-color: $common-dark-gray-color;
      color: $common-white-color;
      transition: background-color $short-transition-time, color $short-transition-time, border-color $short-transition-time;
    }
  }

  // TODAY/BACK/NEXT
  .rbc-btn-group:first-of-type {
    position: absolute;
  }

  // DAY/WEEK/MONTH
  .rbc-btn-group:last-of-type {
    position: absolute;
    right: 0;
    top: -82px;

    @include mobile {
      display: none;
      visibility: hidden;
    }

    //@include small-mobile {
    //  top: -124px;
    //}

    button {
      width: 68px;
    }
  }

  // styling DayPicker inside the calendar
  .DayPicker {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    // more than the rbc-row-content in month calendawr view
    z-index: 5;
    width: $datepicker-width;
    border-radius: 5px;
    overflow: hidden;
    background-color: $common-white-color;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .08);
    border: solid 1px $common-medium-gray-color_accent;
    box-sizing: border-box;
  }

  .DayPicker-Day--selected {
    color: #00a1c3 !important;
    font-weight: bold;

    &:before {
      background-color: transparent !important;
    }
  }
}

.rbc-btn-group {
  display: inline-flex;
  height: 28px;
  border-radius: 3px;
  background-color: $common-white-color;
}

.rbc-custom-back,
.rbc-custom-next,
.rbc-custom-today {
  &:hover {
    cursor: pointer;
  }
}

.rbc-custom-today {
  @extend %typo-content-card-item-header;
  position: absolute;
  right: 20px;
  color: $common-gray-color;
  user-select: none;
  transition: color $short-transition-time;

  &:hover {
    color: $accent-secondary-color;
    transition: color $short-transition-time;
  }

  @include mobile {
    display: none;
  }
}

.rbc-custom-today_disabled {
  &:hover {
    color: $common-gray-color;
    cursor: not-allowed;
  }
}

.rbc-time-view,
.rbc-month-view {
  background-color: $common-white-color;
  border: 0;
}

.rbc-time-view {
  position: static;
  z-index: 1;

  .rbc-time-header {
    padding-right: 14px;
    margin-right: 0 !important;

    .rbc-row:first-child {
      border-bottom: 0;
    }

    .rbc-row:last-of-type {
      display: none;
    }

    .rbc-header {
      padding: 12px 3px 10px;
    }
  }

  .rbc-day-slot {
    .rbc-event {
      //margin-left: 20px;
    }
  }

  .rbc-event-label {
    display: none;
  }

  @include not-desktop {
    overflow-x: auto;
  }
}

.rbc-month-view {
  .rbc-header {
    padding: 20px 3px;
    border-color: $calendar-border-gray-color;
  }

  @include not-desktop {
    overflow-x: auto;
  }
}

.rbc-timeslot-group {
  min-height: 50px;
}

.rbc-time-content {
  border-top: $calendar-border-value;

  //> .rbc-today {
  //  background-color: $common-white-color;
  //}

  @include not-desktop {
    overflow-y: initial !important;
  }
}

.rbc-time-gutter {
  width: 110px !important;

  .rbc-timeslot-group {
    border-left: 0;
  }

  .rbc-label {
    @extend %typo-calendar-time-label;
    padding: 7px 15px 0;
  }

  .rbc-time-slot:last-of-type {
    border-top: $calendar-border-value;
  }
}

.rbc-day-slot {
  .rbc-timeslot-group:last-of-type {
    border-bottom: 0;
  }
}

.rbc-custom-header__week-day {
  @extend %typo-calendar-header-weekday;
}

.rbc-custom-header__date {
  @extend %typo-calendar-header-date;
  margin-top: 3px;
}

.rbc-date-cell {
  padding: 4px 0 4px 6px;
  text-align: left;
}

.rbc-row-segment {
  padding: 0 0 1px 1px;
}

.rbc-show-more {
  @extend %typo-calendar-show-more;
  padding: 4px;
}

.rbc-overlay {
  max-height: 105px;
  border-radius: $common-border-radius;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: $common-shadow;
}

.rbc-overlay-header {
  display: none;
}

.rbc-event {
  border: 2px solid transparent;
  transition: border-color $short-transition-time;

  &:hover {
    border-color: $common-dark-gray-color;
    transition: border-color $short-transition-time;
  }
}

.rbc-day-slot {
  .rbc-event {
    margin: 0 2px;
    padding: 8px 9px;
    border: 2px solid transparent;
    transition: border-color $short-transition-time;

    &:hover {
      border-color: $common-dark-gray-color;
      transition: border-color $short-transition-time;
    }
  }
}

.rbc-time-content,
.rbc-time-header,
.rbc-month-header,
.rbc-month-row {
  @include not-desktop {
    min-width: 877px;
  }
}

.rbc-current-time-indicator {
  z-index: 3;
}

.rbc-custom-day-week-event {
  @extend %typo-calendar-event;
}

.rbc-custom-day-week-event__trainer {
  padding-top: 2px;
}

.rbc-custom-day-week-event__trainer,
.rbc-custom-day-week-event__program {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}
