.dog-selection {
  &__form-content {
    position: relative;
    padding: 0 80px;
    @include mobile {
      padding: 0 20px;
      overflow: hidden;
    }
  }

  &__control {
    .dogs-slider {
      padding: 0 60px;
      @include mobile {
        padding: 0;
      }
    }
  }
}
