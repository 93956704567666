.content-block {
  background: #fff;
  box-shadow: $common-shadow;
  padding: 30px 18px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
  }

  &__header-text {
    font-family: $bold;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
    margin-right: 10px;

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 5px;
      background: $accent-primary-color;
      margin-top: 15px;
    }
  }

  &__header-actions {
    font-size: 16px;
  }

  &__header-action {
    @extend .link;
    font-family: $bold;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.12;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &_column {
      flex-direction: column;

      .content-block__item-header {
        margin-right: 0;
        margin-bottom: 2px;
      }
      .content-block__item-content {
        text-align: left;
      }
    }
  }

  &__item-header {
    font-family: $bold;
    margin-right: 30px;
  }
  &__item-header-content {
    font-family: $preferred_font;
    font-size: 15px;
    margin-top: 2px;
  }
  &__item-content {
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__body {
    transition: height $short-transition-time;

    &_hidden {
      overflow: hidden;
      height: 0;
    }
  }

  &__list {
    @include mobile {
      padding-bottom: 20px;
    }
  }

  &__toggle {
    text-align: center;
    @extend .link;
    border-top: 1px solid $common-medium-gray-color_accent;
    padding: 18px;
    font-size: 14px;
    margin: 0 -18px -30px;
    display: none;

    @include mobile {
      display: block;
    }
  }

  &__text {
    font-size: 16px;
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__add-item {
    display: inline-block;
  }
}
