.pre-confirmation {
  position: relative;

  &__head {
    margin-bottom: 65px;
    @include mobile {
      margin-bottom: 45px;
    }
    &_small-margin {
      margin-bottom: 20px;
    }
  }

  &__block {
    color: $common-dark-gray-color;
    font-size: 16px;
    letter-spacing: .8px;
    line-height: 1.2em;
    display: flex;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 15px 20px;
    @include not-desktop {
      flex-wrap: wrap;
    }
    &_error {
      background: #fee0e0;
      border-color: red;
      margin-bottom: 15px;
    }
    &__title {
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      width: 75px;
      margin-right: 25px;
      @include not-desktop {
        width: 100%;
        margin-bottom: 5px;
        margin-right: 0;
      }
    }
    &_error &__title {
      color: red;
    }
    &__content {
      display: inline-block;
      vertical-align: top;
      max-width: calc(100% - 100px);
      @include not-desktop {
        max-width: 100%;
      }
    }
  }

  &__address {
    margin-bottom: 5px;
    p {
      margin: 0;
    }
  }
  &__address-change {
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    color: $accent-secondary-color;
    letter-spacing: .6px;
    transition: color $duration;
    margin-top: -1px;
    &:hover {
      @extend %faster;
      color: darken($accent-secondary-color, 20%);
    }
  }
}
