.marketing-page {
  @extend .dashboard-page;
  min-height: calc(100vh - #{$page-header-min-height});
}

.marketing-page__header {
  @extend %flex-container-rows-centred-end;
  height: 85px;
  @include mobile {
    height: 40px;
  }
}

.marketing-page__view-icon {
  fill: $common-gray-color;
  margin-right: 20px;
  width: 42px;
  @include mobile {
    display: none;
  }
}

.marketing-page__view-toggle {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.campaign-grid {
  @extend %flex-container-rows-wrapped-centred;
  padding: 30px 20px;
  @include tablet {
    padding: 20px 12px;
  }
  @include mobile {
    padding: 20px;
  }
}

.campaign-grid > .calendar__loader {
  margin: 0 auto;
}

.campaign-card-wrapper {
  display: flex;
  padding: 0 10px;
  width: 280px;
  @include tablet {
    width: 316px;
    padding: 0 8px;
  }
  @include mobile {
    width: 280px;
  }
  &:last-child {
    margin-right: auto;
  }
}

.campaign-card {
  @extend %hover-card;
  width: 100%;
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  border-radius: $common-border-radius;
  background-color: $common-white-color;
  margin-bottom: 30px;

  &:before {
    z-index: 0;
    border-radius: $common-border-radius;
  }

  @include not-desktop {
    margin-bottom: 20px;
  }

  .icon_chevron_down {
    fill: $common-gray-color;
    will-change: opacity;
    transition: opacity $short-transition-time;
    opacity: 0;
  }

  & .icon_chevron_down {
    fill: $common-gray-color;
  }

  &:hover {
    .icon_chevron_down {
      will-change: fill;
      transition: fill $short-transition-time;
      opacity: 1;

      &:hover {
        fill: $common-dark-gray-color;
      }
    }
  }
}

.dropdown__item-edit {
  @include not-desktop {
    display: none;
  }
}

.campaign-card__inner {
  overflow: hidden;
  height: 100%;
}

.campaign-card__image {
  height: 190px;
  font-size: 36px;
  line-height: 190px;
  text-align: center;
  background-color: #f8f8f8;
  border-top-right-radius: $common-border-radius;
  border-top-left-radius: $common-border-radius;
}

.campaign-card__title {
  @extend %typo-card-name;
  padding: 20px;
  border-bottom: solid .5px $common-medium-gray-color_accent;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.campaign-card__content {
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.campaign-card__item {
  @extend .content-card__item;
  text-align: left;
  margin-top: 0 !important;
  margin-bottom: 20px;
}

.campaign-card__item-header {
  @extend .content-card__item-header;
}

.campaign-card__item-content {
  @extend .content-card__item-content;
  display: block;
}
