$vet-info-width: 69.44%;
$vet-info-width_tablet: 86.98%;
$vet-info-logo-width: 29.4%;

.vet-info {
  @extend .signin;
}

.vet-info__img {
  @extend .signin__img;
  background-image: url('/assets/imgs/slide-3.jpg');
  @include not-desktop {
    display: none;
  }
}

.vet-info__content {
  @extend .signin__content;
}

.vet-info__container {
  @include onboarding-container($vet-info-width, $vet-info-width_tablet, $signin-width_mobile);
  min-height: calc(100vh - 26.231rem);
}

// todo: should it be done like container above?
.vet-info__logo {
  @extend .signin__logo;
  width: $vet-info-logo-width;

  @include mobile {
    width: $signin-logo-width_mobile;
  }
}

.vet-info__step-name {
  @extend .introduction__step-name;
}

.vet-info__step-description {
  width: 80.99%;
  margin-top: $common-margin-top-large;

  @include tablet {
    margin: $common-margin-top-large auto 0;

    .step-description__arrow {
      margin: 3.26rem 0 1.52rem;
    }

    .step-description__image {
      padding-bottom: 53.055%;
    }
  }

  @include mobile {
    width: 100%;

    .step-description__disclaimer {
      padding: 0 3.45rem;
    }
  }
}

.vet-info__step-description-image {
  @extend .step-description__image;
  background-image: url('/assets/imgs/slide-3_tablet.jpg');

  @include mobile {
    padding-bottom: 62.5%;
    background-image: url('/assets/imgs/slide-3_mobile.jpg');
  }
}

.vet-info__form {
  @extend .signin__form;
  @extend %flex-container-columns-around;
  flex: 1;
  // #smaller margins
  //margin-top: $common-margin-top-big;
  margin-top: $common-margin-universal-normal;

  .Select {
    z-index: 1;
  }

  .Select-control {
    .Select-value {
      padding-right: 1.7rem !important;
    }
  }

  .Select-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $input-height;
  }

  .Select-multi-value-wrapper {
    display: flex;
    align-items: flex-end;
    height: $input-height;
    margin-bottom: .5rem;
  }

  .Select-input {
    display: flex !important;
    align-items: flex-end;
    margin-left: 0;
    height: $common-margin-top-large_mobile;

    input {
      padding: 0;
      width: auto !important;
      line-height: 1;
    }
  }

  .form__select-aligner {
    margin-top: 1.45rem;

    @include tablet {
      margin-top: 3.26rem;
    }

    @include mobile {
      margin-top: $common-margin-large_tablet;
    }
  }

  .form__select-aligner_heighted {
    // #smaller height
    //min-height: 22.35rem;
    min-height: 16.85rem;
    justify-content: center;

    @include not-desktop {
      min-height: 0;
    }
  }

  @include tablet {
    margin-top: $common-margin-large_tablet;
  }

  @include mobile {
    margin-top: 2.97rem;

    .select-control {
      margin-top: 0;
    }
  }
}

.vet-info__submit-button {
  @extend .signin__submit-button;
  width: 71.65%;

  @include tablet {
    margin-top: 3.26rem;
  }

  @include mobile {
    width: 100%;
    margin-top: 2.97rem;
  }
}

.vet-info__submit-button_disabled {
  @extend .signin__submit-button_disabled;
}

.vet-info__skip-step {
  @extend .introduction__skip-step;

  .skip-step__skip-link {
    // #smaller padding
    //padding: 1.45rem 0 3.41rem;
    padding: $common-margin-top-big 0;

    @include tablet {
      padding-bottom: $common-margin-large_tablet;
    }
  }
}

.vet-info__info {
  @extend %flex-container-columns;
  margin-bottom: .72rem;

  @include mobile {
    margin-top: 1.09rem;
  }
}

.vet-info__info_desktop-tablet {
  @include mobile {
    display: none;
  }
}

.vet-info__info_mobile {
  @include not-mobile {
    display: none;
  }
}

.vet-info__helper {
  @extend .input-control__helper;
  @extend %typo-vet-info-helper;

  @include mobile {
    align-self: center;
  }
}

.vet-info__add-veterinarian {
  @extend .add-veterinarian;
}

.vet-info__add-veterinarian_show {
  @extend .add-veterinarian_show;
}

.vet-info__text {
  text-align: center;
  font-size: 16px;
  max-width: 440px;
  margin: 0 auto;
  color: $common-dark-gray-color;
}
